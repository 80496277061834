import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        home: "Home",
        about: "About Us",
        service: "Service +",
        plot: "Plot Booking +",
        contact: "Contact Us",
        careers: "Careers",
        signIn: "Sign In",
        register: "Register",
        customer: "Customer",
        agent: "Agent",
        homeplaceHolder: "Search Plots by Name, Location etc.",
        perfectPlot: "Find Your Perfect Plot",
        search: "Search",
        totalArea: "Total Area Sqft",
        experience: "Experience",
        amenities: "Amenities",
        soldPlot: "No of Sold Plot",
        company: "Company Overview",
        moreCompany:
          "Founded in 2019, WIND GROUP has quickly risen to become one of the most reputed builders and real estate developers in Rewa, Madhya Pradesh. Our company is driven by a core belief: that ideas form the cornerstones of great buildings, while bricks and mortar are merely tools for realizing these ideas. We see the potential in every plot of land, transforming it into houses that become homes and offices that spark innovation. With a passion for sustainable living and cutting-edge design, WIND GROUP is committed to delivering lifestyle solutions that set new standards in the real estate industry.",
        journey:
          "Our journey began with a clear vision—one that focused on merging insight with design and eco-friendly construction technologies. From residential complexes to commercial spaces, we have successfully completed numerous projects across Rewa, turning acres of land into gated communities, premium townships, and standalone architectural landmarks. Over the years, WIND GROUP has gained a reputation for quality construction, transparency, and a customer-first approach. We are dedicated to building sustainable, value-driven homes and commercial spaces that contribute to a higher standard of living for all.",
        services: "OUR SERVICES",
        plotListing: "Featured Plot Listings",
        plotPlan: "Plot Plan",
        latestNews: "Latest News Feeds",
        client: "Clients Feedback",
        feedback1:
          "I recently had the pleasure of working with WIND GROUP while searching for a new home. From day one, their team was extremely responsive and provided excellent suggestions. Throughout the negotiation and closing process, they were quick to resolve any issues. If I ever need a new home again, there’s no doubt about who I’ll call!",
        feedback2:
          "Manendra from WIND GROUP was incredibly helpful in finding the right property for us. He was always available, offering various suggestions and working with us to make the best decision. We feel like we’ve found a trustworthy agent and a friend in the process.",
        feedback3:
          "Nitin from WIND GROUP was amazing throughout the entire plot purchase process. His communication was clear and timely, and he always listened to what we wanted. He even asked insightful questions that helped us make the right choice.",
        feedback4:
          "We bought our first property through WIND GROUP, and the experience was wonderful! Their team made the entire process easy and stress-free.",
        realEstate: {
          title: "Real Estate Development",
          paragraph1:
            "Real estate development is at the core of what WIND GROUP does. This service involves a multi-step process where we take raw land and transform it into homes, commercial spaces, or community environments that enhance both value and quality of life. Real estate development is not just about constructing buildings; it’s about creating new possibilities and contributing to urban planning and economic growth.",
          paragraph2:
            "At WIND GROUP, we start the development process by identifying promising plots of land with untapped potential. After acquiring the land, we conduct thorough research and feasibility studies to determine the best use for the property, taking into account factors such as location, market demand, and environmental impact. We handle everything from zoning and permits to conceptualizing the design and construction.",
          paragraph3:
            "Our expertise allows us to create projects that are not only aesthetically pleasing but also sustainable and efficient. We integrate eco-friendly technologies, such as rainwater harvesting, energy-efficient lighting, and smart-home technologies, ensuring that our developments meet the needs of modern living while reducing environmental impact. Our commitment to sustainability helps us create healthier living and working environments for future generations.",
          paragraph4:
            "Once the construction phase is complete, we work closely with sales, marketing, and leasing teams to bring the project to market. Our development services span a wide range of projects, including residential communities, commercial complexes, and mixed-use developments. We take pride in creating properties that reflect the needs and aspirations of the people who live and work there. With WIND GROUP’s development services, clients can rest assured that every detail, from the foundation to the finishing touches, is handled with precision and care.",
        },
        salesMarketing: {
          title: "Sales and Marketing",
          paragraph1:
            "Sales and marketing play a crucial role in bringing real estate projects to life. At WIND GROUP, we provide comprehensive sales and marketing services designed to ensure that the properties we develop reach the right audience and are sold or leased as quickly and efficiently as possible. We understand that successful real estate projects depend on effective communication and strategic positioning, which is why our sales and marketing teams are equipped with industry insights, market knowledge, and cutting-edge tools to deliver exceptional results.",
          paragraph2:
            "Our marketing strategies begin with a deep understanding of the target market. We analyze the demographics, preferences, and behaviors of potential buyers or tenants to tailor our messaging accordingly. From this analysis, we create compelling marketing campaigns that highlight the unique features of each property, positioning it as the ideal choice for our audience. These campaigns often include a mix of digital marketing, social media outreach, print advertisements, and real-world events such as property tours and open houses.",
          paragraph3:
            "In addition to marketing the property, our sales team works tirelessly to convert leads into actual sales. We train our agents to be knowledgeable about every aspect of the project, ensuring that they can answer any questions potential buyers may have and address their concerns. Our agents work directly with clients, guiding them through every step of the purchasing process—from initial inquiries to final contracts.",
          paragraph4:
            "At WIND GROUP, we don’t just focus on getting the property sold; we ensure that our clients have a positive experience throughout the process. This customer-first approach has helped us build long-term relationships with buyers, investors, and tenants alike, leading to repeat business and word-of-mouth referrals.",
        },
        brokerage: {
          title: "Brokerage",
          paragraph1:
            "Real estate brokerage is a key service that helps connect buyers and sellers in the real estate market. As a trusted real estate brokerage firm, WIND GROUP facilitates transactions between property owners and potential buyers or investors, ensuring that both parties receive the best possible terms. Our brokerage services are designed to simplify the often complex process of buying and selling property, making it as smooth and transparent as possible.",
          paragraph2:
            "Our brokerage team consists of experienced and licensed real estate agents who specialize in various types of properties, from residential homes and apartments to commercial buildings and land investments. Whether you're a buyer looking for your dream home or a seller seeking to maximize your property’s value, our agents are here to guide you every step of the way. We begin by understanding your unique needs and objectives, allowing us to match you with the most suitable properties or buyers.",
          paragraph3:
            "WIND GROUP brokers work on behalf of both buyers and sellers, ensuring fair negotiations that benefit all parties involved. We assist buyers in finding the right property that meets their budget, location preferences, and lifestyle requirements, and we help sellers by marketing their properties effectively to attract the highest bids. Our team is skilled at navigating the local real estate market and keeping clients informed of trends, prices, and legalities that may impact their decisions.",
          paragraph4:
            "We believe that transparency is crucial in real estate transactions. Our agents keep both buyers and sellers updated throughout the process, ensuring that all legal and financial documentation is handled properly. Whether it’s helping with property inspections, appraisals, or closing deals, WIND GROUP’s brokerage services are designed to remove stress and confusion from the real estate process, so you can focus on your next big move.",
        },
        propertyManagement: {
          title: "Property Management",
          paragraph1:
            "Property management is one of the most essential services for property owners, whether they own a single rental unit or a large portfolio of residential or commercial properties. WIND GROUP offers full-service property management solutions designed to take the burden off owners while maximizing the value of their investments. Our goal is to manage properties in a way that ensures optimal tenant satisfaction, regular maintenance, and steady cash flow.",
          paragraph2:
            "Our property management services begin with tenant acquisition. We use advanced screening techniques to find reliable tenants who meet the financial and legal requirements for leasing a property. Once tenants are in place, our team handles all aspects of day-to-day management, including rent collection, maintenance requests, and addressing tenant concerns. We provide ongoing support, making sure that the property is well-maintained and that any issues are addressed promptly and professionally.",
          paragraph3:
            "Maintenance and repairs are another critical aspect of our property management services. We coordinate with trusted contractors and service providers to ensure that repairs are done quickly and at competitive rates. Regular inspections are conducted to keep properties in excellent condition, helping owners avoid costly repairs down the line and ensuring that tenants are satisfied with their living or working environment.",
          paragraph4:
            "Our property management team also handles financial management, providing property owners with detailed reports on rent collection, expenses, and other key financial metrics. By maintaining accurate records and handling the financial side of property ownership, we help our clients stay compliant with local regulations and maximize the return on their investment.",
          paragraph5:
            "At WIND GROUP, we are committed to providing property owners with peace of mind by managing their assets efficiently and effectively. Whether you own a residential building, commercial complex, or mixed-use property, our team of property management professionals is here to take care of every detail, ensuring your property continues to generate value and offer an excellent tenant experience.",
        },
        faq: {
          title1: "Who should I contact in case I face any issues?",
          content1:
            "You can reach out to our customer service team via phone or email for any assistance.",
          title2: "Do you offer home loan services?",
          content2:
            "While we don’t directly provide home loans, we can connect you with trusted financial institutions to facilitate your loan application.",
          title3: "What kind of properties are listed on your website?",
          content3:
            "We list a wide range of properties, including residential plots, homes, commercial spaces and more.",
          title4:
            "What documents and formalities are required when buying property?",
          content4:
            "You’ll need to provide identity proof, property documents, and financial statements. Our team will guide you through all the necessary paperwork.",
          title5:
            "Are there any upcoming developments or infrastructure projects nearby?",
          content5:
            "Yes, Rewa is undergoing significant development, and we are continuously working on new projects that promise great investment potential.",
          title6: "What are the hidden charges besides the market rate?",
          content6:
            "We maintain transparency in our dealings and will inform you about any additional charges, such as registration fees, maintenance costs, or taxes upfront.",
          support: "Still need help? Reach out to support 24/7:",
          contact: "Contact Us",
          subscribe: "// subscribe",
          newsletter: "Get Newsletter",
        },
      },
    },
    hi: {
      translation: {
        home: "होम",
        about: "के बारे में",
        service: "सेवा +",
        plot: "प्लॉट बुकिंग +",
        contact: "संपर्क करें",
        careers: "करियर",
        signIn: "साइन इन",
        register: "रजिस्टर करें",
        customer: "ग्राहक",
        agent: "एजेंट",
        homeplaceHolder: "नाम, स्थान आदि द्वारा प्लॉट खोजें.",
        perfectPlot: "अपना सही प्लॉट ढूंढें",
        search: "खोजें",
        totalArea: "कुल क्षेत्रफल वर्गफुट",
        experience: "अनुभव",
        amenities: "सुविधाएँ",
        soldPlot: "बेचे गए प्लॉट की संख्या",
        company: "कंपनी का परिचय",
        moreCompany:
          "2019 में स्थापित, WIND GROUP ने तेजी से रिवा, मध्य प्रदेश में सबसे प्रतिष्ठित बिल्डर्स और रियल एस्टेट डेवलपर्स में से एक के रूप में अपनी पहचान बनाई है। हमारी कंपनी एक मूल विश्वास से प्रेरित है: कि विचार महान इमारतों की नींव होते हैं, जबकि ईंट और सीमेंट केवल इन विचारों को वास्तविकता में बदलने के उपकरण होते हैं। हम हर ज़मीन के टुकड़े में संभावनाओं को देखते हैं, उसे ऐसे घरों में बदलते हैं जो घर बन जाएं और ऐसे कार्यालयों में जो नवाचार को प्रेरित करें। सतत जीवनशैली और अत्याधुनिक डिज़ाइन के प्रति अपनी भावना के साथ, WIND GROUP रियल एस्टेट उद्योग में नए मानक स्थापित करने वाले जीवनशैली समाधान प्रदान करने के लिए प्रतिबद्ध है।",
        journey:
          "हमारी यात्रा एक स्पष्ट दृष्टिकोण के साथ शुरू हुई थी—जो डिज़ाइन और पारिस्थितिकी अनुकूल निर्माण प्रौद्योगिकियों को अंतर्दृष्टि के साथ मिलाने पर केंद्रित था। आवासीय परिसर से लेकर वाणिज्यिक स्थानों तक, हमने रिवा में कई परियोजनाओं को सफलतापूर्वक पूरा किया है, ज़मीन के एक-एक एकड़ को गेटेड समुदायों, प्रीमियम टाउनशिप्स और स्वतंत्र वास्तुशिल्प प्रतीकों में बदलते हुए। वर्षों के दौरान, WIND GROUP ने गुणवत्ता निर्माण, पारदर्शिता और ग्राहक-पहले दृष्टिकोण के लिए अपनी पहचान बनाई है। हम टिकाऊ, मूल्य-आधारित घरों और वाणिज्यिक स्थानों का निर्माण करने के लिए प्रतिबद्ध हैं, जो सभी के लिए जीवन के उच्च मानक में योगदान करें।",
        services: "हमारी सेवाएँ",
        plotListing: "विशिष्ट प्लॉट लिस्टिंग्स",
        plotPlan: "प्लॉट योजना",
        latestNews: "ताज़ा समाचार",
        client: "ग्राहकों की प्रतिक्रिया",
        feedback1:
          "हाल ही में मुझे WIND GROUP के साथ काम करने का अवसर मिला जब मैं अपने लिए नया घर ढूंढ रहा था। पहले दिन से ही उनकी टीम बेहद उत्तरदायी थी और उन्होंने उत्कृष्ट सुझाव दिए। बातचीत और समापन प्रक्रिया के दौरान, उन्होंने किसी भी समस्या को जल्दी हल किया। अगर मुझे फिर कभी नया घर चाहिए, तो मुझे इस बात का कोई संदेह नहीं है कि मैं किससे संपर्क करूंगा!",
        feedback2:
          "WIND GROUP से मनेन्द्र हमारे लिए सही संपत्ति ढूंढने में बेहद मददगार रहे। वह हमेशा उपलब्ध थे, विभिन्न सुझाव देते थे और हमारे साथ मिलकर सबसे अच्छा निर्णय लेने में मदद करते थे। हमें लगता है कि हमने इस प्रक्रिया में एक भरोसेमंद एजेंट और एक दोस्त पाया है।",
        feedback3:
          "WIND GROUP से नितिन पूरे प्लॉट खरीदने की प्रक्रिया में अद्भुत रहे। उनकी संवाद शैली स्पष्ट और समय पर थी, और वह हमेशा हमारे इच्छाओं को ध्यान से सुनते थे। उन्होंने ऐसे समझदारी से सवाल पूछे, जिन्होंने हमें सही विकल्प चुनने में मदद की।",
        feedback4:
          "हमने अपनी पहली संपत्ति WIND GROUP के माध्यम से खरीदी, और यह अनुभव अद्भुत था! उनकी टीम ने पूरी प्रक्रिया को आसान और तनावमुक्त बना दिया।",
        realEstate: {
          title: "रियल एस्टेट विकास",
          paragraph1:
            "रियल एस्टेट विकास WIND GROUP के मुख्य कार्यों में से एक है। यह सेवा एक बहु-चरणीय प्रक्रिया है जहाँ हम कच्ची जमीन को घरों, वाणिज्यिक स्थानों, या सामुदायिक वातावरण में बदलते हैं जो मूल्य और जीवन की गुणवत्ता को बढ़ाते हैं। रियल एस्टेट विकास केवल इमारतों के निर्माण के बारे में नहीं है; यह नई संभावनाएँ बनाने और शहरी योजना और आर्थिक विकास में योगदान करने के बारे में है।",
          paragraph2:
            "WIND GROUP में, हम विकास प्रक्रिया की शुरुआत संभावित क्षमता वाली जमीन की पहचान से करते हैं। भूमि के अधिग्रहण के बाद, हम गहन शोध और व्यवहार्यता अध्ययन करते हैं ताकि संपत्ति के लिए सबसे अच्छा उपयोग निर्धारित किया जा सके, जिसमें स्थान, बाजार की मांग और पर्यावरणीय प्रभाव जैसे कारकों को ध्यान में रखा जाता है। हम ज़ोनिंग और परमिट से लेकर डिज़ाइन और निर्माण की अवधारणा तक सब कुछ संभालते हैं।",
          paragraph3:
            "हमारी विशेषज्ञता हमें न केवल सौंदर्यपूर्ण रूप से आकर्षक बल्कि टिकाऊ और कुशल परियोजनाएँ बनाने की अनुमति देती है। हम पर्यावरण के अनुकूल तकनीकों को एकीकृत करते हैं, जैसे वर्षा जल संचयन, ऊर्जा-कुशल प्रकाश, और स्मार्ट-होम तकनीकें, यह सुनिश्चित करते हुए कि हमारे विकास आधुनिक जीवन की आवश्यकताओं को पूरा करते हुए पर्यावरणीय प्रभाव को कम करते हैं। हमारी स्थिरता के प्रति प्रतिबद्धता हमें भविष्य की पीढ़ियों के लिए स्वस्थ रहने और काम करने के वातावरण बनाने में मदद करती है।",
          paragraph4:
            "निर्माण चरण पूरा होने के बाद, हम बिक्री, विपणन और पट्टे टीमों के साथ मिलकर परियोजना को बाजार में लाने के लिए काम करते हैं। हमारी विकास सेवाओं में आवासीय समुदाय, वाणिज्यिक परिसरों और मिश्रित-उपयोग विकास सहित कई परियोजनाएँ शामिल हैं। हम ऐसी संपत्तियाँ बनाने पर गर्व करते हैं जो वहाँ रहने और काम करने वाले लोगों की आवश्यकताओं और आकांक्षाओं को दर्शाती हैं। WIND GROUP की विकास सेवाओं के साथ, ग्राहक निश्चिंत हो सकते हैं कि हर विवरण, नींव से लेकर फिनिशिंग टच तक, सावधानीपूर्वक और ध्यान से संभाला गया है।",
        },
        salesMarketing: {
          title: "विक्रय और विपणन",
          paragraph1:
            "विक्रय और विपणन रियल एस्टेट परियोजनाओं को जीवंत बनाने में एक महत्वपूर्ण भूमिका निभाते हैं। WIND GROUP में, हम व्यापक विक्रय और विपणन सेवाएँ प्रदान करते हैं जो यह सुनिश्चित करती हैं कि हम जो संपत्तियाँ विकसित करते हैं, वे सही दर्शकों तक पहुँचें और उन्हें यथाशीघ्र और कुशलतापूर्वक बेचा या पट्टे पर दिया जा सके। हम समझते हैं कि सफल रियल एस्टेट परियोजनाएँ प्रभावी संचार और रणनीतिक स्थिति पर निर्भर करती हैं, यही कारण है कि हमारी विक्रय और विपणन टीमें उद्योग की अंतर्दृष्टि, बाजार का ज्ञान और अत्याधुनिक उपकरणों से सुसज्जित हैं।",
          paragraph2:
            "हमारी विपणन रणनीतियाँ लक्ष्य बाजार की गहरी समझ से शुरू होती हैं। हम संभावित खरीदारों या किरायेदारों की जनसांख्यिकी, प्राथमिकताओं और व्यवहारों का विश्लेषण करते हैं ताकि हम अपने संदेश को तदनुसार तैयार कर सकें। इस विश्लेषण के आधार पर, हम आकर्षक विपणन अभियान बनाते हैं जो प्रत्येक संपत्ति की अनूठी विशेषताओं को उजागर करते हैं, इसे हमारे दर्शकों के लिए आदर्श विकल्प के रूप में स्थापित करते हैं।",
          paragraph3:
            "संपत्ति का विपणन करने के अलावा, हमारी विक्रय टीम लीड्स को वास्तविक विक्रय में बदलने के लिए अथक प्रयास करती है। हम अपने एजेंटों को परियोजना के हर पहलू के बारे में जानकार बनने के लिए प्रशिक्षित करते हैं, यह सुनिश्चित करते हुए कि वे संभावित खरीदारों के किसी भी प्रश्न का उत्तर दे सकें और उनकी चिंताओं को दूर कर सकें।",
          paragraph4:
            "WIND GROUP में, हम केवल संपत्ति बेचने पर ध्यान केंद्रित नहीं करते; हम यह सुनिश्चित करते हैं कि हमारे ग्राहकों को पूरी प्रक्रिया में एक सकारात्मक अनुभव हो। इस ग्राहक-प्रथम दृष्टिकोण ने हमें खरीदारों, निवेशकों, और किरायेदारों के साथ दीर्घकालिक संबंध बनाने में मदद की है।",
        },
        brokerage: {
          title: "दलाली",
          paragraph1:
            "रियल एस्टेट दलाली एक महत्वपूर्ण सेवा है जो रियल एस्टेट बाजार में खरीदारों और विक्रेताओं को जोड़ने में मदद करती है। एक विश्वसनीय रियल एस्टेट दलाली फर्म के रूप में, WIND GROUP संपत्ति मालिकों और संभावित खरीदारों या निवेशकों के बीच लेनदेन को सुगम बनाता है।",
          paragraph2:
            "हमारी दलाली टीम अनुभवी और लाइसेंस प्राप्त रियल एस्टेट एजेंटों से बनी है जो विभिन्न प्रकार की संपत्तियों में विशेषज्ञ हैं।",
          paragraph3:
            "WIND GROUP के ब्रोकर खरीदारों और विक्रेताओं दोनों की ओर से काम करते हैं, यह सुनिश्चित करते हुए कि सभी पक्षों के लिए लाभकारी और निष्पक्ष बातचीत हो। हम खरीदारों को उनकी बजट, स्थान की प्राथमिकताओं और जीवनशैली की आवश्यकताओं के अनुसार सही संपत्ति ढूंढने में मदद करते हैं, और हम विक्रेताओं को उनकी संपत्तियों का प्रभावी तरीके से प्रचार करके उच्चतम बोलियां आकर्षित करने में सहायता करते हैं। हमारी टीम स्थानीय रियल एस्टेट बाजार को समझने में माहिर है और ग्राहकों को ऐसे रुझानों, कीमतों और कानूनी मामलों की जानकारी देती है, जो उनके निर्णयों को प्रभावित कर सकते हैं।",
          paragraph4:
            "हमें विश्वास है कि रियल एस्टेट लेन-देन में पारदर्शिता अत्यंत महत्वपूर्ण है। हमारे एजेंट खरीदारों और विक्रेताओं को पूरे प्रक्रिया के दौरान अपडेट रखते हैं, यह सुनिश्चित करते हुए कि सभी कानूनी और वित्तीय दस्तावेज़ सही तरीके से संभाले जाएं। चाहे वह संपत्ति निरीक्षण, मूल्यांकन, या सौदों को बंद करना हो, WIND GROUP की ब्रोकर सेवा इस उद्देश्य के लिए डिज़ाइन की गई है कि रियल एस्टेट प्रक्रिया से तनाव और भ्रम को दूर किया जा सके, ताकि आप अपनी अगली बड़ी चाल पर ध्यान केंद्रित कर सकें।",
        },
        propertyManagement: {
          title: "संपत्ति प्रबंधन",
          paragraph1:
            "संपत्ति प्रबंधन संपत्ति मालिकों के लिए सबसे आवश्यक सेवाओं में से एक है, चाहे उनके पास एक ही किराये की इकाई हो या आवासीय या वाणिज्यिक संपत्तियों का बड़ा पोर्टफोलियो। WIND GROUP सम्पूर्ण सेवा संपत्ति प्रबंधन समाधान प्रदान करता है, जो मालिकों से बोझ को कम करते हुए उनके निवेश का अधिकतम मूल्य बढ़ाने के लिए डिज़ाइन किए गए हैं। हमारा उद्देश्य इस प्रकार से संपत्तियों का प्रबंधन करना है कि किरायेदारों की संतुष्टि, नियमित रखरखाव और स्थिर नकदी प्रवाह सुनिश्चित हो सके।",
          paragraph2:
            "हमारी संपत्ति प्रबंधन सेवाएँ किरायेदार अधिग्रहण से शुरू होती हैं। हम उन्नत स्क्रीनिंग तकनीकों का उपयोग करते हैं ताकि हम ऐसे विश्वसनीय किरायेदारों को पा सकें जो संपत्ति को किराए पर लेने के लिए वित्तीय और कानूनी आवश्यकताओं को पूरा करते हों। एक बार किरायेदारों का चयन हो जाने के बाद, हमारी टीम रोज़ाना के प्रबंधन के सभी पहलुओं को संभालती है, जिसमें किराया संग्रह, रखरखाव अनुरोध और किरायेदारों की चिंताओं का समाधान करना शामिल है। हम निरंतर समर्थन प्रदान करते हैं, यह सुनिश्चित करते हुए कि संपत्ति अच्छी तरह से संरक्षित है और किसी भी समस्या का समाधान तुरंत और पेशेवर तरीके से किया जाए।",
          paragraph3:
            "रखरखाव और मरम्मत हमारी संपत्ति प्रबंधन सेवाओं का एक और महत्वपूर्ण पहलू है। हम विश्वसनीय ठेकेदारों और सेवा प्रदाताओं के साथ समन्वय करते हैं ताकि मरम्मत जल्दी और प्रतिस्पर्धी दरों पर की जा सके। नियमित निरीक्षण किए जाते हैं ताकि संपत्तियाँ उत्कृष्ट स्थिति में बनी रहें, जिससे मालिकों को भविष्य में महंगी मरम्मत से बचने में मदद मिलती है और यह सुनिश्चित होता है कि किरायेदार अपने रहने या काम करने के माहौल से संतुष्ट हों।",
          paragraph4:
            "हमारी संपत्ति प्रबंधन टीम वित्तीय प्रबंधन भी संभालती है, जो संपत्ति मालिकों को किराया संग्रह, खर्चों और अन्य प्रमुख वित्तीय मापदंडों पर विस्तृत रिपोर्ट प्रदान करती है। सही रिकॉर्ड बनाए रखते हुए और संपत्ति स्वामित्व के वित्तीय पहलू को संभालते हुए, हम अपने ग्राहकों को स्थानीय नियमों के अनुसार बने रहने में मदद करते हैं और उनके निवेश पर अधिकतम लाभ प्राप्त करने में सहायता करते हैं।",
          paragraph5:
            "WIND GROUP में, हम संपत्ति मालिकों को मानसिक शांति प्रदान करने के लिए प्रतिबद्ध हैं, उनके संपत्तियों का कुशलतापूर्वक और प्रभावी तरीके से प्रबंधन करते हैं। चाहे आपके पास आवासीय भवन हो, वाणिज्यिक परिसर हो या मिश्रित-उपयोग संपत्ति हो, हमारी संपत्ति प्रबंधन पेशेवरों की टीम हर विवरण का ध्यान रखने के लिए यहाँ है, यह सुनिश्चित करते हुए कि आपकी संपत्ति मूल्य उत्पन्न करना जारी रखे और किरायेदारों को उत्कृष्ट अनुभव प्रदान करे।",
        },
        faq: {
          title1: "मुझे किसी समस्या का सामना करना पड़े तो किससे संपर्क करूं?",
          content1:
            "सहायता के लिए आप हमारे ग्राहक सेवा दल से फोन या ईमेल के माध्यम से संपर्क कर सकते हैं।",
          title2: "क्या आप होम लोन सेवाएं प्रदान करते हैं?",
          content2:
            "हम सीधे होम लोन नहीं प्रदान करते हैं, लेकिन हम आपके लोन आवेदन को सुगम बनाने के लिए विश्वसनीय वित्तीय संस्थानों से संपर्क कर सकते हैं।",
          title3: "आपकी वेबसाइट पर किस प्रकार की संपत्तियां सूचीबद्ध हैं?",
          content3:
            "हम कई प्रकार की संपत्तियां सूचीबद्ध करते हैं, जिनमें आवासीय भूखंड, घर, वाणिज्यिक स्थान और अन्य शामिल हैं।",
          title4:
            "संपत्ति खरीदने पर किन दस्तावेज़ों और औपचारिकताओं की आवश्यकता होती है?",
          content4:
            "आपको पहचान प्रमाण, संपत्ति दस्तावेज़, और वित्तीय विवरण प्रदान करने होंगे। हमारी टीम आपको सभी आवश्यक कागजी कार्रवाई के लिए मार्गदर्शन करेगी।",
          title5:
            "क्या पास में कोई नई विकास परियोजनाएं या अवसंरचना परियोजनाएं चल रही हैं?",
          content5:
            "हाँ, रीवा में महत्वपूर्ण विकास हो रहा है, और हम लगातार नई परियोजनाओं पर काम कर रहे हैं जो शानदार निवेश की संभावनाएं प्रदान करती हैं।",
          title6: "बाजार दर के अलावा और कौन से छुपे हुए शुल्क हैं?",
          content6:
            "हम अपने व्यवहार में पारदर्शिता बनाए रखते हैं और आपको किसी भी अतिरिक्त शुल्क, जैसे पंजीकरण शुल्क, रखरखाव लागत, या करों के बारे में पहले ही सूचित करेंगे।",
          support: "अभी भी मदद की ज़रूरत है? 24/7 समर्थन से संपर्क करें:",
          contact: "संपर्क करें",
          subscribe: "// सदस्यता लें",
          newsletter: "न्यूज़लेटर प्राप्त करें",
        },
      },
    },
  },
  lng: "en", // default language
  fallbackLng: "en", // fallback language
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
