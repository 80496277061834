import React from "react";
import Footer from "../component/footer";
import Navbar from "../component/navbar";
import PageHeader from "../component/Page-header";
import ProductV1 from "../component/productV1";
import { useLocation } from "react-router-dom";

const Product = () => {
  const location = useLocation();
  return (
    <div>
      <Navbar CustomClass="ltn__header-transparent gradient-color-2" />
      <PageHeader
        headertitle={
          location.pathname == "/availablePlot" ? "Available Plot" : "Product"
        }
        subheader={
          location.pathname == "/availablePlot" ? "availablePlot" : "product"
        }
      />
      <ProductV1 />
      <Footer />
    </div>
  );
};

export default Product;
