import React from "react";
import CareerPage from "../component/careerPage";
import Footer from "../component/footer";
import Navbar from "../component/navbar";
import PageHeader from "../component/Page-header";

const Career = () => {
  return (
    <div>
      <Navbar CustomClass="ltn__header-transparent gradient-color-2" />
      <PageHeader headertitle="Careers" subheader="Careers" />
      <CareerPage />
      <Footer />
    </div>
  );
};

export default Career;
