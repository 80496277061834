import React from "react";
import { Link } from "react-router-dom";
import about from "../assets/img/aboutUs.png";
import aboutVideo from "../assets/video/aboutUs.mp4";
import { useTranslation } from "react-i18next";

const AboutUsCard = () => {

  const {t} = useTranslation()

  return (
    <div className="ltn__about-us-area pt-120 pb-50">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 align-self-center">
            <div className="about-us-img-wrap about-img-left">
              <img src={about} alt="About Us" height={800} width={600} />
              <div className="about-us-img-info about-us-img-info-2 about-us-img-info-3">
                <div className="ltn__video-img ltn__animation-pulse1">
                  <img src={about} alt="Video Popup Background" />
                  <a
                    className="ltn__video-icon-2 ltn__video-icon-2-border---"
                    href={aboutVideo}
                    data-rel="lightcase:myCollection"
                  >
                    <i className="fa fa-play" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 align-self-center">
            <div className="about-us-info-wrap">
              <div className="section-title-area ltn__section-title-2--- mb-20">
                <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                  {t("about")}
                </h6>
                <h1 className="section-title">
                  {t("company")} <span>.</span>
                </h1>
                <p style={{ textAlign: "justify" }}>
                 {t("moreCompany")}
                </p>
              </div>
              <ul className="ltn__list-item-half clearfix">
                <li>
                  <i className="flaticon-home-2" />
                  Our Strength
                </li>
                <li>
                  <i className="flaticon-mountain" />
                  Our Achievements
                </li>
              </ul>
              <div className="ltn__callout bg-overlay-theme-05 mt-30">
                <p style={{ textAlign: "justify" }}>
                  {t('journey')}
                </p>
              </div>
              <div className="btn-wrapper animated go-top">
                <Link to="/service" className="theme-btn-1 btn btn-effect-1">
                 {t('services')}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsCard;
