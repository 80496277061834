import React from "react";
import { Link } from "react-router-dom";
import aboutUsCard2 from "../assets/img/aboutUs2.png";

const AboutUs2 = () => {
  return (
    <div className="ltn__about-us-area pb-115 go-top">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 align-self-center">
            <div className="about-us-img-wrap ltn__img-shape-left about-img-left">
              <img src={aboutUsCard2} alt="Image" />
            </div>
          </div>
          <div className="col-lg-7 align-self-center">
            <div className="about-us-info-wrap">
              <div className="section-title-area ltn__section-title-2--- mb-20">
                <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                  About Us
                </h6>
                <h1 className="section-title">
                  Company Overview <span>.</span>
                </h1>
                <p style={{ textAlign: "justify" }}>
                  Founded in 2019, WIND GROUP has quickly risen to become one of
                  the most reputed builders and real estate developers in Rewa,
                  Madhya Pradesh. Our company is driven by a core belief: that
                  ideas form the cornerstones of great buildings, while bricks
                  and mortar are merely tools for realizing these ideas. We see
                  the potential in every plot of land, transforming it into
                  houses that become homes and offices that spark innovation.
                  With a passion for sustainable living and cutting-edge design,
                  WIND GROUP is committed to delivering lifestyle solutions that
                  set new standards in the real estate industry.
                </p>
              </div>
              <ul className="ltn__list-item-half clearfix">
                <li>
                  <i className="flaticon-home-2" />
                  Our Strength
                </li>
                <li>
                  <i className="flaticon-mountain" />
                  Our Achievements
                </li>
              </ul>
              <div className="ltn__callout bg-overlay-theme-05 mt-30">
                <p style={{ textAlign: "justify" }}>
                  Our journey began with a clear vision—one that focused on
                  merging insight with design and eco-friendly construction
                  technologies. From residential complexes to commercial spaces,
                  we have successfully completed numerous projects across Rewa,
                  turning acres of land into gated communities, premium
                  townships, and standalone architectural landmarks. Over the
                  years, WIND GROUP has gained a reputation for quality
                  construction, transparency, and a customer-first approach. We
                  are dedicated to building sustainable, value-driven homes and
                  commercial spaces that contribute to a higher standard of
                  living for all.
                </p>
              </div>
              <div className="btn-wrapper animated">
                <Link
                  to="/about"
                  className="theme-btn-1 btn btn-effect-1 text-uppercase"
                >
                  About Us
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs2;
