import React from "react";
import { Link } from "react-router-dom";
import newsFeed1 from "../assets/img/newsFeed1.png";
import newsFeed2 from "../assets/img/newsFeed2.png";
import newsFeed3 from "../assets/img/newsFeed3.png";

import { useTranslation } from "react-i18next";

const BlogSlider = ({ customClass, sectionClass }) => {
  const { t } = useTranslation();

  const data = [
    {
      id: 1,
      img: newsFeed1,
      name: "Sustainable Real Estate",
      subName:
        "Sustainable Real Estate: How WIND GROUP is Pioneering Eco-Friendly Construction in Rewa",
      date: "October 22, 2024",
    },
    {
      id: 2,
      img: newsFeed2,
      name: "The Role of Customer-Centricity in Real Estate",
      subName:
        "The Role of Customer-Centricity in Real Estate: How WIND GROUP Builds Relationships That Last",
      date: "September 19, 2024",
    },
    {
      id: 3,
      img: newsFeed3,
      name: "Transforming Rewa",
      subName:
        "Transforming Rewa: WIND GROUP’s Vision for Modern, Sustainable, and Connected Communities",
      date: "November 27, 2024",
    },
  ];

  return (
    <div
      className={`ltn__blog-area pt-115--- pb-70 go-top ${sectionClass}`}
      style={{ marginTop: "20px" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title-area ltn__section-title-2--- text-center">
              <h6
                className={`section-subtitle ltn__secondary-color section-subtitle-2`}
              >
                News &amp; Blogs
              </h6>
              <h1 className="section-title">{t("latestNews")}</h1>
            </div>
          </div>
        </div>
        <div className="row ltn__blog-slider-one-active slick-arrow-1 ltn__blog-item-3-normal">
          {/* Blog Item */}
          {data.map((blog, index) => (
            <div className="col-lg-12" key={index}>
              <div className="ltn__blog-item ltn__blog-item-3">
                <div className="ltn__blog-img">
                  <Link to={`/blog-details/${blog.id}`}>
                    <img src={blog.img} alt="#" />
                  </Link>
                </div>
                <div className="ltn__blog-brief">
                  <div className="ltn__blog-meta">
                    <ul>
                      <li className="ltn__blog-author">
                        <Link to="/team-details">
                          <i className="far fa-user" />
                          by: Admin
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <h3 className="ltn__blog-title">
                    <Link to={`/blog-details/${blog.id}`}>{blog.subName}</Link>
                  </h3>
                  <div className="ltn__blog-meta-btn">
                    <div className="ltn__blog-meta">
                      <ul>
                        <li className="ltn__blog-date">
                          <i className="far fa-calendar-alt" />
                          {blog.date}
                        </li>
                      </ul>
                    </div>
                    <div className="ltn__blog-btn">
                      <Link to={`/blog-details/${blog.id}`}>Read more</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlogSlider;
