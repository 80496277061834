import React from "react";
import { Link } from "react-router-dom";
import plot9 from "../assets/img/plot9.jpg";

const PageHeader = ({ headertitle, subheader, customclass, Img }) => {
  const HeaderTitle = headertitle;
  const publicUrl = process.env.PUBLIC_URL + "/";
  const Subheader = subheader ? subheader : HeaderTitle;
  const CustomClass = customclass ? customclass : "";
  const imageSrc = Img ? Img : "14.jpg";

  return (
    <div
      className={`ltn__breadcrumb-area text-left bg-overlay-black-20 bg-image ${CustomClass}`}
      data-bs-bg={plot9}
    >
      <div className="container" style={{ marginTop: "100px" }}>
        <div className="row">
          <div className="col-lg-12">
            <div className="ltn__breadcrumb-inner">
              <h1 className="page-title text-white">{HeaderTitle}</h1>
              <div className="ltn__breadcrumb-list">
                <ul className="text-white">
                  <li>
                    <Link to="/">
                      <span className="ltn__secondary-color">
                        <i className="fas fa-home" />
                      </span>{" "}
                      Home
                    </Link>
                  </li>
                  <li className="text-white">{Subheader}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageHeader;
