import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import plot7 from "../assets/img/plot7.PNG";
import logo from "../assets/img/logo2.png";
import { getAllPlot, getAvailablePlot } from "../service/axiosInstance";
import { debounce } from "lodash";
import Select from "react-select";
import { useSelector } from "react-redux";

const ProductV1 = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(12);
  const [total, setTotal] = useState(0);
  const [search1, setSearch1] = useState("");
  const location = useLocation();

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border:
        state.isFocused || state.isHovered
          ? "1px solid #e7c44f"
          : "2px solid #e5eaee",
      boxShadow: state.isFocused ? "none" : "none", // Removes the default focus shadow
      borderRadius: "0",
      height: "50px",
      fontSize: "14px",
      fontWeight: "700",
      lineHeight: "45px",
      width: "100%",
      marginBottom: "8px",
      "&:hover": {
        border: "1px solid #e7c44f", // Border color on hover
        boxShadow: "none", // No default shadow on hover
      },
      position: "relative", // Position context for the dropdown indicator
      display: "flex",
      alignItems: "center", // Aligns text and icon vertically
      justifyContent: "space-between", // Positions the icon to the right
      cursor: "pointer", // Change cursor to indicate interactivity
      color: "#6B7F89",
      paddingBottom: "20px",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontWeight: "700",
      paddingBottom: "20px",
    }),
    indicatorSeparator: (provided) => ({
      display: "none",
      paddingBottom: "45px",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#e7c44f",
      paddingBottom: "45px",
    }),
    menuList: (provided) => ({
      ...provided,
      backgroundColor: "white", // Set background for the options menu
      zIndex: 1,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor:
        state.isFocused || state.isSelected
          ? "#f6f6f6"
          : state.isHovered
          ? "#f1f1f1"
          : "white", // Grey on hover
      color: state.isFocused || state.isSelected ? "#647984" : "#6B7F89", // Change text color on hover or selection
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#f1f1f1", // Grey background for hovered option
      },
      zIndex: 9999999999999,
      paddingLeft: "20px",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#888", // Placeholder text color
      paddingBottom: "20px",
    }),

    valueContainer: (provided) => ({
      ...provided,
      color: "#6B7F89",
      paddingLeft: "15px", // Add padding to the left for the selected value
      paddingBottom: "20px",
    }),
  };

  const options = [
    { value: "12", label: "Per Page: 12" },
    { value: "20", label: "Per Page: 20" },
    { value: "30", label: "Per Page: 30" },
    { value: "50", label: "Per Page: 50" },
    { value: "100", label: "Per Page: 100" },
  ];

  const { search } = useSelector((state) => state.auth);
  // Calculate total pages
  const totalPages = Math.ceil(total / limit);

  const handleSelectChange = (e) => {
    const selectedValue = e.value;
    setLimit(parseInt(selectedValue));
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  const getAllMainPlot = async () => {
    try {
      let resp;
      if (location.pathname == "/availablePlot") {
        resp = await getAvailablePlot({
          page: currentPage,
          limit: limit,
          name: search || search1,
        });
        setData(resp.data.plots);
        setTotal(resp.data.totalResults);
      } else {
        resp = await getAllPlot({
          page: currentPage,
          limit: limit,
          name: search || search1,
          area: search || search1,
        });
        setData(resp.data.data);
        setTotal(resp.data.total);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const renderPagination = () => {
    const pagination = [];
    let pageRange = 2;

    for (let i = 1; i <= totalPages; i++) {
      if (
        i === 1 ||
        i === totalPages ||
        (i >= currentPage - pageRange && i <= currentPage + pageRange)
      ) {
        pagination.push(
          <li key={i} className={currentPage === i ? "active" : ""}>
            <Link to="#" onClick={() => handlePageClick(i)}>
              {i}
            </Link>
          </li>
        );
      }
    }

    return pagination;
  };

  useEffect(() => {
    getAllMainPlot();
  }, [location, search1, search, currentPage, limit]);

  return (
    <div>
      <div className="ltn__product-area ltn__product-gutter">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 mb-100">
              <div className="ltn__shop-options">
                <ul className="justify-content-start">
                  <li>
                    <div className="ltn__grid-list-tab-menu ">
                      <div className="nav">
                        <a
                          className="active show"
                          data-bs-toggle="tab"
                          href="#liton_product_grid"
                        >
                          <i className="fas fa-th-large" />
                        </a>
                        <a data-bs-toggle="tab" href="#liton_product_list">
                          <i className="fas fa-list" />
                        </a>
                      </div>
                    </div>
                  </li>
                  <li className="">
                    <div className="showing-product-number text-right">
                      <span>
                        Showing {(currentPage - 1) * limit + 1}–
                        {Math.min(currentPage * limit, total)} of {total}{" "}
                        results
                      </span>
                    </div>
                  </li>
                  <Select
                    name="service"
                    value={options.find((option) => option.value == limit)}
                    onChange={handleSelectChange}
                    options={options}
                    styles={customStyles}
                    placeholder="Select Service Type"
                    isSearchable={false} // Disabling the search option
                  />
                </ul>
              </div>
              <div className="tab-content">
                <div
                  className="tab-pane fade active show"
                  id="liton_product_grid"
                >
                  <div className="ltn__product-tab-content-inner ltn__product-grid-view">
                    <div className="row">
                      <div className="col-lg-12">
                        {/* Search Widget */}
                        <div className="ltn__search-widget mb-30">
                          <form action="#">
                            <input
                              type="text"
                              name="search"
                              placeholder="Search plots by location, size, price, etc."
                              onChange={(e) => setSearch1(e.target.value)}
                              style={{ placeholder: { fontSize: "20px" } }}
                            />
                            {/* <button type="submit" onSubmit={handleSearch}>
                              <i className="fas fa-search" />
                            </button> */}
                          </form>
                        </div>
                      </div>
                      {data &&
                        data?.map((element) => {
                          let totalSubplots = 0;
                          let minPrice = Infinity;
                          let maxPrice = -Infinity;
                          let minSize = Infinity;
                          let maxSize = -Infinity;
                          element.blocks.forEach((block) => {
                            totalSubplots += block.sub_plot.length;
                            block.sub_plot.forEach((subPlot) => {
                              minPrice = Math.min(minPrice, subPlot.price);
                              maxPrice = Math.max(maxPrice, subPlot.price);

                              const sizeInSqFt = parseInt(
                                subPlot.size.split(" ")[0],
                                10
                              ); // Extract size from "100 sq ft"
                              minSize = Math.min(minSize, sizeInSqFt);
                              maxSize = Math.max(maxSize, sizeInSqFt);
                            });
                          });
                          return (
                            <>
                              {" "}
                              {/* ltn__product-item */}
                              <div className="col-xl-4 col-sm-6 col-12">
                                <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
                                  <div className="product-img go-top">
                                    {/* <Link to="/product-details"> */}
                                    <img
                                      src={element?.media?.images[0]}
                                      alt="#"
                                    />
                                    {/* </Link> */}
                                    <div className="real-estate-agent">
                                      <div className="agent-img">
                                        <Link to="/shop">
                                          <img src={logo} alt="#" />
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="product-info">
                                    <h2 className="product-title go-top">
                                      <Link to="/product-details">
                                        {element.name}
                                      </Link>
                                    </h2>
                                    <div className="product-description">
                                      <p className="line-clamp-3">
                                        {element.description}
                                      </p>
                                    </div>
                                    <div className="product-img-location go-top">
                                      <ul>
                                        <li>
                                          <Link to="/contact">
                                            <i className="flaticon-pin" />
                                            {element.location.area},{" "}
                                            {element.location.state}
                                          </Link>
                                        </li>
                                      </ul>
                                    </div>
                                    <ul className="ltn__plot-brief">
                                      <li>
                                        <span>
                                          {" "}
                                          {minSize == maxSize ? (
                                            <> {minSize}</>
                                          ) : (
                                            <>
                                              {minSize} - {maxSize}
                                            </>
                                          )}
                                        </span>{" "}
                                        <i className="flaticon-square-shape-design-interface-tool-symbol" />
                                        Square Ft
                                      </li>
                                    </ul>
                                    <div className="mb-2 line-clamp-1">
                                      {element.amenities.map((e) => {
                                        return (
                                          <span className="mr-2">{e},</span>
                                        );
                                      })}
                                    </div>
                                    <div className="mb-2">
                                      <span className="font-semibold">
                                        No of Blocks -
                                      </span>{" "}
                                      {element.blocks.length}
                                    </div>
                                    <div className="mb-2">
                                      <span className="font-semibold">
                                        No of Sub Plots -
                                      </span>{" "}
                                      {totalSubplots}
                                    </div>
                                  </div>
                                  <div className="product-info-bottom">
                                    <div className="product-price">
                                      <span className="text-black">
                                        {minPrice == maxPrice ? (
                                          <>₹ {minPrice}</>
                                        ) : (
                                          <>
                                            ₹ {minPrice} - ₹ {maxPrice}
                                          </>
                                        )}
                                      </span>
                                    </div>
                                    <div className="text-right">
                                      <button
                                        type="submit"
                                        className="btnForBook theme-btn-1 text-[#262626] rounded-sm"
                                        onClick={() =>
                                          navigate(
                                            `/product-details/${element._id}`
                                          )
                                        }
                                      >
                                        View More
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="liton_product_list">
                  <div className="ltn__product-tab-content-inner ltn__product-list-view">
                    <div className="row">
                      <div className="col-lg-12">
                        {/* Search Widget */}
                        <div className="ltn__search-widget mb-30">
                          <form action="#">
                            <input
                              type="text"
                              name="search"
                              placeholder="Search your keyword..."
                            />
                            <button type="submit">
                              <i className="fas fa-search" />
                            </button>
                          </form>
                        </div>
                      </div>

                      {data.map((element) => {
                        let totalSubplots = 0;
                        let minPrice = Infinity;
                        let maxPrice = -Infinity;
                        let minSize = Infinity;
                        let maxSize = -Infinity;
                        element.blocks.forEach((block) => {
                          totalSubplots += block.sub_plot.length;
                          block.sub_plot.forEach((subPlot) => {
                            minPrice = Math.min(minPrice, subPlot.price);
                            maxPrice = Math.max(maxPrice, subPlot.price);

                            const sizeInSqFt = parseInt(
                              subPlot.size.split(" ")[0],
                              10
                            ); // Extract size from "100 sq ft"
                            minSize = Math.min(minSize, sizeInSqFt);
                            maxSize = Math.max(maxSize, sizeInSqFt);
                          });
                        });
                        return (
                          <>
                            <div className="col-lg-12">
                              <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5">
                                <div
                                  className="product-img1 product-img go-top"
                                  // style={{ maxWidth: "40%" }}
                                >
                                  <img
                                    src={element?.media?.images[0]}
                                    alt="#"
                                  />
                                </div>
                                <div className="product-info">
                                  <h2 className="product-title go-top">
                                    <Link to="/product-details">
                                      {element.name}
                                    </Link>
                                  </h2>
                                  <div className="product-description">
                                    <p className="line-clamp-1">
                                      {element.description}
                                    </p>
                                  </div>
                                  <div className="product-img-location go-top">
                                    <ul>
                                      <li>
                                        <Link to="/contact">
                                          <i className="flaticon-pin" />{" "}
                                          {element.location.area},{" "}
                                          {element.location.state}
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>
                                  <ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
                                    <li>
                                      <span>
                                        {" "}
                                        {minSize == maxSize ? (
                                          <> {minSize}</>
                                        ) : (
                                          <>
                                            {minSize} - {maxSize}
                                          </>
                                        )}
                                      </span>{" "}
                                      Square Ft
                                    </li>
                                  </ul>
                                  <div className="mb-2">
                                    {element.amenities.map((e) => {
                                      return <span className="mr-2">{e},</span>;
                                    })}
                                  </div>
                                  <div className="mb-2">
                                    <span className="font-semibold">
                                      No of Blocks -
                                    </span>{" "}
                                    {element.blocks.length}
                                  </div>
                                  <div className="mb-2">
                                    <span className="font-semibold">
                                      No of Sub Plots -
                                    </span>{" "}
                                    {totalSubplots}
                                  </div>
                                  <div className="product-badge-price">
                                    <div className="product-price">
                                      <span className="text-black">
                                        {" "}
                                        {minPrice == maxPrice ? (
                                          <>₹ {minPrice}</>
                                        ) : (
                                          <>
                                            ₹ {minPrice} - ₹ {maxPrice}
                                          </>
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="text-right">
                                    <button
                                      type="submit"
                                      style={{
                                        borderRadius: "2px",
                                        marginBottom: "0px",
                                      }}
                                      className="btnForBook theme-btn-1 text-[#262626]"
                                      onClick={() =>
                                        navigate(
                                          `/product-details/${element._id}`
                                        )
                                      }
                                    >
                                      View More
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="ltn__pagination-area text-center">
                <div className="ltn__pagination">
                  <ul>
                    <li>
                      <Link
                        to="#"
                        onClick={() =>
                          handlePageClick(currentPage > 1 ? currentPage - 1 : 1)
                        }
                      >
                        <i className="fas fa-angle-double-left" />
                      </Link>
                    </li>

                    {/* Render pagination links dynamically */}
                    {renderPagination()}

                    {/* Next page button */}
                    <li>
                      <Link
                        to="#"
                        onClick={() =>
                          handlePageClick(
                            currentPage < totalPages
                              ? currentPage + 1
                              : totalPages
                          )
                        }
                      >
                        <i className="fas fa-angle-double-right" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductV1;
