import React from "react";
import { Link } from "react-router-dom";

const ServiceV2 = () => {
  const publicUrl = process.env.PUBLIC_URL + "/";

  return (
    <div className="ltn__service-area section-bg-1 pt-115 pb-70 go-top">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title-area ltn__section-title-2--- text-center">
              <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                Our Services
              </h6>
              <h1 className="section-title">Our Core Services</h1>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          {[
            {
              id: "realEstate",
              icon: "flaticon-mortgage",
              title: "Real Estate Development",
              link: "/service-details/realEstate",
            },
            {
              id: "salesMarketing",
              icon: "flaticon-house-3",
              title: "Sales and Marketing",
              link: "/service-details/salesMarketing",
            },
            {
              id: "brokerage",
              icon: "flaticon-official-documents",
              title: "Brokerage",
              link: "/service-details/brokerage",
            },
            {
              id: "propertyManagement",
              icon: "flaticon-house",
              title: "Property Management",
              link: "/service-details/propertyManagement",
            },
          ].map((service, index) => (
            <div className="col-lg-3 col-sm-6 col-12" key={index}>
              <div
                className="ltn__feature-item ltn__feature-item-6 text-center bg-white box-shadow-1"
                style={{ maxHeight: "214px" }}
              >
                <div className="ltn__feature-icon">
                  <span>
                    <i className={service.icon} />
                  </span>
                </div>
                <div className="ltn__feature-info">
                  <h3>
                    <Link to={service.link}>{service.title}</Link>
                  </h3>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ServiceV2;
