import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import plotSiteLayout from "../assets/img/plotDetails.png";
import samplePdf from "../assets/pdf/sample.pdf";
import { getAllPlot } from "../service/axiosInstance";
import { useTranslation } from "react-i18next";

const Apartment = ({ CustomClass }) => {
  CustomClass = CustomClass ? CustomClass : "";

  const [data, setData] = useState([]);

  const { t } = useTranslation();

  const getAllMainPlot = async () => {
    try {
      const resp = await getAllPlot({
        page: 1,
        limit: 5,
      });
      setData(resp.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllMainPlot();
  }, []);

  return (
    <>
      <div
        className={`ltn__apartments-plan-area pt-115--- pb-70 ${CustomClass}`}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2--- text-center">
                <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                  Plot Sketch
                </h6>
                <h1 className="section-title">{t("plotPlan")}</h1>
              </div>
              <div className="ltn__tab-menu ltn__tab-menu-3 ltn__tab-menu-top-right-- text-uppercase--- text-center">
                <div className="nav">
                  {data.map((e, i) => {
                    return i == 1 ? (
                      <a
                        className="active show"
                        data-bs-toggle="tab"
                        href={`#liton_tab_3_${i + 1}`}
                      >
                        {e.name}
                      </a>
                    ) : (
                      <a data-bs-toggle="tab" href={`#liton_tab_3_${i + 1}`}>
                        {e.name}
                      </a>
                    );
                  })}
                </div>
              </div>
              <div className="tab-content">
                {data.map((e, index) => {
                  let totalSubplots = 0;
                  let minPrice = Infinity;
                  let maxPrice = -Infinity;
                  let minSize = Infinity;
                  let maxSize = -Infinity;
                  e.blocks.forEach((block) => {
                    totalSubplots += block.sub_plot.length;
                    block.sub_plot.forEach((subPlot) => {
                      minPrice = Math.min(minPrice, subPlot.price);
                      maxPrice = Math.max(maxPrice, subPlot.price);

                      const sizeInSqFt = parseInt(
                        subPlot.size.split(" ")[0],
                        10
                      ); // Extract size from "100 sq ft"
                      minSize = Math.min(minSize, sizeInSqFt);
                      maxSize = Math.max(maxSize, sizeInSqFt);
                    });
                  });
                  return (
                    <div
                      key={`#liton_tab_3_${index + 1}`}
                      className={`tab-pane fade ${
                        index === 1 ? "active show" : ""
                      }`}
                      id={`liton_tab_3_${index + 1}`}
                    >
                      <div className="ltn__product-tab-content-inner">
                        <div className="row">
                          <div className="col-lg-6">
                            <div
                              className="apartments-plan-info-1 ltn__secondary-bg text-color-white"
                              style={{
                                backgroundColor: "#262626",
                                minHeight: "700px",
                              }}
                            >
                              <h2>{e.name}</h2>
                              <p>{e.description}</p>
                              <Link to="/contact">
                                <i className="flaticon-pin" />
                                <span className="text-lg mx-2">
                                  {" "}
                                  {e.location.area}, {e.location.state}
                                </span>
                              </Link>
                              <div className="mt-2">
                                Price : ₹ {minPrice} - ₹ {maxPrice}
                              </div>
                              <div className="my-2">
                                {e.amenities.map((e) => {
                                  return <span className="mr-2">{e},</span>;
                                })}
                              </div>
                              <div className="apartments-info-list apartments-info-list-color mt-30">
                                <ul>
                                  <li>
                                    <label
                                      style={{ backgroundColor: "#262626" }}
                                    >
                                      Total Area
                                    </label>{" "}
                                    <span
                                      style={{ backgroundColor: "#262626" }}
                                    >
                                      {minSize}-{maxSize} Square ft
                                    </span>
                                  </li>

                                  <li>
                                    <label
                                      style={{ backgroundColor: "#262626" }}
                                    >
                                      No of Plots
                                    </label>{" "}
                                    <span
                                      style={{ backgroundColor: "#262626" }}
                                    >
                                      {totalSubplots}
                                    </span>
                                  </li>
                                </ul>
                              </div>
                              <div className="text-right mt-3">
                                {/* <a href={samplePdf} download>
                                  <button
                                    type="submit"
                                    style={{ borderRadius: "2px" }}
                                    className="btnForBook theme-btn-plot"
                                  >
                                    Download Overview
                                  </button>
                                </a> */}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="apartments-plan-img flex justify-center">
                              <div>
                                {/* Thumbnail Image that Opens Modal */}
                                <img
                                  src={
                                    e.location?.site_layout[0] || plotSiteLayout
                                  }
                                  alt={e.name}
                                  height={700}
                                  width={500}
                                  className="cursor-pointer transition-transform transform hover:scale-110"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Apartment;
