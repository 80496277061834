import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getAllPlot } from "../service/axiosInstance";
import { useTranslation } from "react-i18next";
import $ from "jquery"; // Ensure jQuery is available
import "slick-carousel"; // Make sure Slick is loaded

const ProductSlider = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const { t } = useTranslation();
  const [isSliderInitialized, setIsSliderInitialized] = useState(false); // Track slider initialization state

  const sliderRef = useRef(null); // Ref to access the slider container

  // Fetch data
  const getAllMainPlot = async () => {
    try {
      const resp = await getAllPlot({ page: 1, limit: 5 });
      setData(resp.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllMainPlot();

    // Initialize the Slick slider only when data is available
    const $slider = $(sliderRef.current);

    const initializeSlider = () => {
      if ($slider && $slider.length && data.length > 0) {
        // Destroy the previous slider instance if it exists
        if ($slider.hasClass("slick-initialized")) {
          $slider.slick("unslick");
        }

        // Initialize the slider
        $slider.slick({
          arrows: true,
          dots: true,
          infinite: true,
          speed: 300,
          slidesToShow: 4,
          slidesToScroll: 1,
          prevArrow:
            '<a class="slick-prev"><i class="fas fa-arrow-left"></i></a>',
          nextArrow:
            '<a class="slick-next"><i class="fas fa-arrow-right"></i></a>',
          responsive: [
            {
              breakpoint: 1800,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 575,
              settings: {
                arrows: false,
                dots: true,
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ],
        });
      }
      setIsSliderInitialized(true);
    };

    // Initialize the slider when data is loaded
    if (data.length > 0) {
      initializeSlider();
    }

    // Cleanup on component unmount
    return () => {
      if ($slider && $slider.hasClass("slick-initialized")) {
        // $slider.slick("unslick");
      }
      setIsSliderInitialized(false);
    };
  }, [data.length, isSliderInitialized]); // Depend on data to re-initialize if needed

  return (
    <div>
      <div className="ltn__product-slider-area ltn__product-gutter pt-115 pb-90 plr--7">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2--- text-center">
                <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                  Plot Area
                </h6>
                <h1 className="section-title">{t("plotListing")}</h1>
              </div>
            </div>
          </div>
          <div
            className="row ltn__product-slider-item-four-active-full-width slick-arrow-1"
            ref={sliderRef}
          >
            {data.length > 0 &&
              data.map((element) => {
                let totalSubplots = 0;
                let minPrice = Infinity;
                let maxPrice = -Infinity;
                let minSize = Infinity;
                let maxSize = -Infinity;
                element.blocks.forEach((block) => {
                  totalSubplots += block.sub_plot.length;
                  block.sub_plot.forEach((subPlot) => {
                    minPrice = Math.min(minPrice, subPlot.price);
                    maxPrice = Math.max(maxPrice, subPlot.price);

                    const sizeInSqFt = parseInt(subPlot.size.split(" ")[0], 10); // Extract size from "100 sq ft"
                    minSize = Math.min(minSize, sizeInSqFt);
                    maxSize = Math.max(maxSize, sizeInSqFt);
                  });
                });
                return (
                  <>
                    <div className="col-lg-12" key={element._id}>
                      <div
                        className="ltn__product-item ltn__product-item-4 text-center---"
                        style={{ height: "640px" }}
                      >
                        <div className="product-img go-top">
                          <Link to="/product-details">
                            <img src={element.media.images[0]} alt="#" />
                          </Link>
                          <div className="product-badge">
                            <ul>
                              <li>{totalSubplots} Plots</li>
                            </ul>
                          </div>
                          <div className="product-img-location-gallery">
                            <div className="product-img-location">
                              <ul>
                                <li>
                                  <Link to="/contact">
                                    <i className="flaticon-pin" />{" "}
                                    {element.location.area},{" "}
                                    {element.location.state}
                                  </Link>
                                </li>
                              </ul>
                            </div>
                            <div className="product-img-gallery go-top">
                              <ul>
                                <li>
                                  <Link to={`/product-details/${element._id}`}>
                                    <i className="fas fa-camera" />{" "}
                                    {element.media.images.length}
                                  </Link>
                                </li>
                                <li>
                                  <Link to={`/product-details/${element._id}`}>
                                    <i className="fas fa-film" />{" "}
                                    {element.media.videos.length}
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="product-info">
                          <div className="product-price">
                            <span>
                              {" "}
                              {minPrice === maxPrice ? (
                                <>₹ {minPrice}</>
                              ) : (
                                <>
                                  ₹ {minPrice} - ₹ {maxPrice}
                                </>
                              )}
                            </span>
                          </div>
                          <h2 className="product-title go-top">
                            <span>{element.name}</span>
                          </h2>
                          <div className="product-description line-clamp-3">
                            <p>{element.description}</p>
                          </div>
                          <ul
                            className="ltn__list-item-2 ltn__list-item-2-before"
                            style={{ marginBottom: "10px" }}
                          >
                            <li style={{ marginTop: "10px" }}>
                              <span>
                                {" "}
                                {minSize === maxSize ? (
                                  <> {minSize}</>
                                ) : (
                                  <>
                                    {minSize} - {maxSize}
                                  </>
                                )}
                                <i className="flaticon-square-shape-design-interface-tool-symbol" />
                              </span>
                              square Ft
                            </li>
                          </ul>
                          <div style={{ marginTop: "0px" }}>
                            <Link to="/contact">
                              <i
                                className="flaticon-pin"
                                style={{
                                  paddingRight: "5px",
                                  paddingLeft: "0px",
                                }}
                              />
                              {element.location.area}, {element.location.state}
                            </Link>
                          </div>
                          <div className="mt-2 line-clamp-1">
                            {element.amenities.map((e) => {
                              return <span className="mr-2">{e},</span>;
                            })}
                          </div>
                          <div className="flex justify-end items-end">
                            <div className="text-right">
                              <button
                                type="submit"
                                style={{ borderRadius: "2px" }}
                                className="btnForBook theme-btn-1 text-[#262626] absolute right-4 bottom-2"
                                onClick={() =>
                                  navigate(`product-details/${element._id}`)
                                }
                              >
                                View Details
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductSlider;
