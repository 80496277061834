import React from "react";
import { useTranslation } from "react-i18next";

const Counter = () => {

  const {t} = useTranslation()

  return (
    <div className="ltn__counterup-area section-bg-1 responsive-padding">
      <div className="container">
        <div className="row">
          <div className="col-md-3 col-sm-6 col-6 align-self-center">
            <div className="ltn__counterup-item text-color-white---">
              <div className="counter-icon">
                <i className="flaticon-select animate-pulse text-4xl" />
              </div>
              <h1>
                <span className="counter">10,00,000</span>
                <span className="counterUp-icon">+</span>
              </h1>
              <h6>{t("totalArea")}</h6>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-6 align-self-center">
            <div className="ltn__counterup-item text-color-white---">
              <div className="counter-icon">
                <i className="flaticon-office animate-pulse text-4xl" />
              </div>
              <h1>
                <span className="counter">20</span>
                <span className="counterUp-letter"></span>
                <span className="counterUp-icon">+ Years</span>
              </h1>
              <h6>{t("experience")}</h6>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-6 align-self-center">
            <div className="ltn__counterup-item text-color-white---">
              <div className="counter-icon">
                <i className="flaticon-apartment animate-pulse text-4xl" />
              </div>
              <h1>
                <span className="counter">268</span>
                <span className="counterUp-icon">+</span>
              </h1>
              <h6>{t("amenities")}</h6>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-6 align-self-center">
            <div className="ltn__counterup-item text-color-white---">
              <div className="counter-icon">
                <i className="flaticon-key animate-pulse text-4xl" />
              </div>
              <h1>
                <span className="counter">340</span>
                <span className="counterUp-icon">+</span>
              </h1>
              <h6>{t("soldPlot")}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Counter;
