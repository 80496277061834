import React from "react";
import { Link, useParams } from "react-router-dom";
import service11 from "../assets/img/service11.jpg";
import service12 from "../assets/img/service12.jpg";
import service13 from "../assets/img/service13.jpg";
import service14 from "../assets/img/service14.jpg";

import service21 from "../assets/img/service21.jpg";
import service22 from "../assets/img/service22.jpg";
import service23 from "../assets/img/service23.jpg";
import service24 from "../assets/img/service24.jpg";

import service31 from "../assets/img/service31.jpg";
import service32 from "../assets/img/service32.jpg";
import service33 from "../assets/img/service33.jpg";
import service34 from "../assets/img/service34.jpg";

import service41 from "../assets/img/service41.jpg";
import service42 from "../assets/img/service42.jpg";
import service43 from "../assets/img/service43.jpg";
import service44 from "../assets/img/service44.png";

import plotCopy from "../assets/img/plot_copy.jpg";
import { useTranslation } from "react-i18next";

const RealEstateDetails = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const data = [
    {
      id: "realEstate",
      icon: "flaticon-mortgage",
      img1: service11,
      img2: service12,
      img3: service13,
      img4: service14,
      title: `${t("realEstate.title")}`,
      link: "/service-details/realEstate",
      paragraph1: `${t("realEstate.paragraph1")}`,
      paragraph2: `${t("realEstate.paragraph2")}`,
      paragraph3: `${t("realEstate.paragraph3")}`,
      paragraph4: `${t("realEstate.paragraph4")}`,
    },
    {
      id: "salesMarketing",
      icon: "flaticon-house-3",
      img1: service21,
      img2: service22,
      img3: service23,
      img4: service24,
      title: `${t("salesMarketing.title")}`,
      link: "/service-details/salesMarketing",
      paragraph1: `${t("salesMarketing.paragraph1")}`,
      paragraph2: `${t("salesMarketing.paragraph2")}`,
      paragraph3: `${t("salesMarketing.paragraph3")}`,
      paragraph4: `${t("salesMarketing.paragraph4")}`,
    },
    {
      id: "brokerage",
      icon: "flaticon-official-documents",
      img1: service31,
      img2: service32,
      img3: service33,
      img4: service34,
      title: `${t("brokerage.title")}`,
      link: "/service-details/brokerage",
      paragraph1: `${t("brokerage.paragraph1")}`,
      paragraph2: `${t("brokerage.paragraph2")}`,
      paragraph3: `${t("brokerage.paragraph3")}`,
      paragraph4: `${t("brokerage.paragraph4")}`,
    },
    {
      id: "propertyManagement",
      icon: "flaticon-house",
      img1: service41,
      img2: service42,
      img3: service43,
      img4: service44,
      title: `${t("propertyManagement.title")}`,
      link: "/service-details/propertyManagement",
      paragraph1: `${t("propertyManagement.paragraph1")}`,
      paragraph2: `${t("propertyManagement.paragraph2")}`,
      paragraph3: `${t("propertyManagement.paragraph3")}`,
      paragraph4: `${t("propertyManagement.paragraph4")}`,
      paragraph5: `${t("propertyManagement.paragraph5")}`,
    },
  ];

  let selectedData = data[0];

  if (id) {
    selectedData = data.find((item) => item.id === id);
  }

  return (
    <div className="ltn__page-details-area ltn__service-details-area mb-105">
      <div className="container">
        <h2 style={{ marginBottom: "30px" }}>{selectedData?.title}</h2>
        <div className="row">
          <div className="col-lg-8">
            <div className="ltn__page-details-inner ltn__service-details-inner">
              <div className="ltn__blog-img">
                <img src={selectedData.img1} alt="Image" />
              </div>
              <p>
                <span className="ltn__first-letter">
                  {selectedData.id.split("")[0]}
                </span>
                {selectedData?.paragraph1}
              </p>
              <p>{selectedData?.paragraph2}</p>
              <div className="row">
                <div className="col-lg-6">
                  <img src={selectedData.img2} alt="image" height={500} />
                  <label>{selectedData?.title}</label>
                </div>
                <div className="col-lg-6">
                  <img src={selectedData.img3} alt="image" height={500} />
                </div>
              </div>
              <p>{selectedData?.paragraph3}</p>
              <p>{selectedData?.paragraph4}</p>
              {selectedData.id == "propertyManagement" ? (
                <p>{selectedData?.paragraph5}</p>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-lg-4">
            <aside className="sidebar-area ltn__right-sidebar">
              {/* Newsletter Widget */}
              {/* <div className="widget ltn__search-widget ltn__newsletter-widget">
                <h6 className="ltn__widget-sub-title">// subscribe</h6>
                <h4 className="ltn__widget-title">Get Newsletter</h4>
                <form action="#">
                  <input type="text" name="search" placeholder="Search" />
                  <button type="submit">
                    <i className="fas fa-search" />
                  </button>
                </form>
                <div className="ltn__newsletter-bg-icon">
                  <i className="fas fa-envelope-open-text" />
                </div>
              </div> */}
              {/* Banner Widget */}
              <div className="widget ltn__banner-widget go-top">
                <Link to="/shop">
                  <img src={selectedData.img4} alt="Banner Image" />
                </Link>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RealEstateDetails;
