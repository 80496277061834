import React from "react";
import { Link, useParams } from "react-router-dom";
import Sidebar from "./sidebar";
import newsFeed1 from "../assets/img/newsFeed1.png";
import newsFeed2 from "../assets/img/newsFeed2.png";
import newsFeed3 from "../assets/img/newsFeed3.png";
import newsFeed11 from "../assets/img/newsFeed11.png";
import newsFeed12 from "../assets/img/newsFeed12.png";
import newsFeed31 from "../assets/img/newsFeed31.png";
import newsFeed21 from "../assets/img/newsFeed21.png";
import newsFeed22 from "../assets/img/newsFeed22.png";
import newsFeed32 from "../assets/img/newsFeed32.jpg";

const BlogDetails = () => {
  const { id } = useParams();

  const data = [
    {
      id: 1,
      img: newsFeed1,
      name: "Sustainable Real Estate",
      subName:
        "Sustainable Real Estate: How WIND GROUP is Pioneering Eco-Friendly Construction in Rewa",
      date: "October 22, 2024",
      link: "/blog-details/1",
    },
    {
      id: 2,
      img: newsFeed2,
      name: "The Role of Customer-Centricity in Real Estate",
      subName:
        "The Role of Customer-Centricity in Real Estate: How WIND GROUP Builds Relationships That Last",
      date: "September 19, 2024",
      link: "/blog-details/2",
    },
    {
      id: 3,
      img: newsFeed3,
      name: "Transforming Rewa",
      subName:
        "Transforming Rewa: WIND GROUP’s Vision for Modern, Sustainable, and Connected Communities",
      date: "November 27, 2024",
      link: "/blog-details/3",
    },
  ];

  let selectedData = data[0];
  let relatedPost;

  if (id) {
    selectedData = data.find((item) => item.id === parseInt(id));
    relatedPost = data.filter((item) => item.id != parseInt(id));
  }

  return (
    <div className="ltn__page-details-area ltn__blog-details-area mb-120">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="ltn__blog-details-wrap">
              <div className="ltn__page-details-inner ltn__blog-details-inner">
                <div className="ltn__blog-meta">
                  <ul>
                    <li className="ltn__blog-category">
                      <Link to="/product">{selectedData.name}</Link>
                    </li>
                  </ul>
                </div>
                <h2 className="ltn__blog-title">{selectedData.subName}</h2>
                <div className="ltn__blog-meta">
                  <ul>
                    <li className="ltn__blog-date">
                      <i className="far fa-calendar-alt" />
                      {selectedData.date}
                    </li>
                  </ul>
                </div>
                <p
                  className="break-words"
                  style={{
                    wordBreak: "auto-phrase", // Adjusts word breaking behavior
                    lineHeight: "1.6",
                  }}
                >
                  {selectedData.id == 1 ? (
                    <>
                      {" "}
                      As the world grapples with climate change and resource
                      depletion, industries across the globe are shifting
                      towards sustainability. The real estate sector is no
                      exception. Increasingly, developers are focusing on
                      eco-friendly practices that reduce environmental impact
                      while providing energy-efficient, healthy living spaces
                      for homeowners. In Rewa, one company stands out in this
                      movement—WIND GROUP. Since its inception in 2019, WIND
                      GROUP has been leading the charge for sustainable real
                      estate development in Rewa, setting new standards for
                      eco-friendly construction and green building technologies.
                    </>
                  ) : selectedData.id == 2 ? (
                    <>
                      In the competitive world of real estate development,
                      creating long-term, meaningful relationships with clients
                      is not just a nice-to-have—it’s essential for success.
                      WIND GROUP, a renowned real estate developer based in
                      Rewa, has mastered this approach with its unwavering focus
                      on customer-centricity. Since its inception in 2019, WIND
                      GROUP has demonstrated that a client-focused mindset is
                      key to building trust, loyalty, and delivering quality
                      projects that exceed customer expectations.
                    </>
                  ) : (
                    <>
                      Rewa is undergoing a remarkable transformation, rapidly
                      becoming a hub for innovative real estate development. At
                      the forefront of this evolution is WIND GROUP, a company
                      with a bold vision for creating modern, sustainable, and
                      connected communities. Since its inception, WIND GROUP has
                      been dedicated to redefining Rewa’s urban landscape
                      through thoughtfully designed gated communities,
                      commercial landmarks, and eco-friendly residential
                      projects. This blog delves into WIND GROUP’s vision for
                      Rewa’s future, how their projects are enhancing local
                      infrastructure, and the balance they strike between
                      modernity and sustainability.
                    </>
                  )}
                </p>
                {selectedData.id == 1 ? (
                  <p
                    style={{
                      wordBreak: "auto-phrase", // Adjusts word breaking behavior
                      lineHeight: "1.6",
                    }}
                  >
                    In this blog, we will explore the importance of
                    sustainability in real estate, the eco-friendly construction
                    materials and technologies used by WIND GROUP, and how these
                    sustainable efforts benefit both the environment and
                    homeowners. Finally, we’ll look at examples of WIND GROUP’s
                    projects that exemplify these green initiatives.
                  </p>
                ) : selectedData.id == 2 ? (
                  <p
                    style={{
                      wordBreak: "auto-phrase", // Adjusts word breaking behavior
                      lineHeight: "1.6",
                    }}
                  >
                    This blog will explore why customer-centricity is crucial in
                    real estate, how WIND GROUP uses transparent processes to
                    foster trust, and how listening to and addressing customer
                    needs is at the heart of their development strategy. We’ll
                    also feature real testimonials from satisfied clients,
                    showcasing how WIND GROUP has successfully built lasting
                    relationships in real estate.
                  </p>
                ) : (
                  <></>
                )}
                <img src={selectedData.img} alt="Image" />
                {selectedData.id == 1 ? (
                  <>
                    {" "}
                    <h2>
                      The Importance of Sustainability in Real Estate
                      Development
                    </h2>
                    <p
                      style={{
                        wordBreak: "auto-phrase", // Adjusts word breaking behavior
                        lineHeight: "1.6",
                      }}
                    >
                      Sustainability is no longer a choice but a necessity in
                      real estate development. The building and construction
                      industry contributes significantly to global carbon
                      emissions, with traditional building materials and
                      construction processes generating significant waste. As
                      awareness of these environmental challenges grows, real
                      estate developers like WIND GROUP are prioritizing
                      sustainable real estate development.
                    </p>
                    <h3 className="font-semibold">
                      Eco-friendly construction helps in multiple ways:
                    </h3>
                    <p
                      style={{
                        wordBreak: "auto-phrase", // Adjusts word breaking behavior
                        lineHeight: "1.6",
                      }}
                    >
                      <span className="font-semibold text-black">
                        • Reduction in carbon emissions:
                      </span>{" "}
                      Sustainable practices reduce the carbon footprint of
                      buildings, both during construction and in the long-term
                      operation of the building.
                    </p>
                    <p
                      style={{
                        wordBreak: "auto-phrase", // Adjusts word breaking behavior
                        lineHeight: "1.6",
                      }}
                    >
                      <span className="font-semibold text-black">
                        • Resource conservation:
                      </span>{" "}
                      Using recycled and sustainable materials limits the
                      depletion of natural resources.
                    </p>
                    <p
                      style={{
                        wordBreak: "auto-phrase", // Adjusts word breaking behavior
                        lineHeight: "1.6",
                      }}
                    >
                      <span className="font-semibold text-black">
                        • Healthier living environments:
                      </span>{" "}
                      Eco-friendly buildings ensure better indoor air quality
                      and healthier living spaces, thanks to non-toxic materials
                      and efficient ventilation systems.
                    </p>
                    <p
                      style={{
                        wordBreak: "auto-phrase", // Adjusts word breaking behavior
                        lineHeight: "1.6",
                      }}
                    >
                      Sustainable real estate isn’t just about building
                      environmentally responsible homes; it’s about creating a
                      better quality of life for residents while protecting the
                      planet for future generations. WIND GROUP's commitment to
                      sustainability is transforming the real estate landscape
                      in Rewa.
                    </p>
                    <hr />
                    <h2>
                      Eco-Friendly Building Materials and Technologies Used by
                      WIND GROUP
                    </h2>
                    <p
                      style={{
                        wordBreak: "auto-phrase", // Adjusts word breaking behavior
                        lineHeight: "1.6",
                      }}
                    >
                      WIND GROUP has incorporated a range of green building
                      technologies and sustainable materials into its projects,
                      setting new benchmarks in eco-friendly construction. Let’s
                      take a closer look at some of the innovations WIND GROUP
                      is using to promote sustainable development.
                    </p>
                    <h3 className="font-semibold">
                      1. Sustainable Building Materials
                    </h3>
                    <p
                      style={{
                        wordBreak: "auto-phrase", // Adjusts word breaking behavior
                        lineHeight: "1.6",
                      }}
                    >
                      WIND GROUP uses eco-friendly materials that minimize
                      environmental impact. For example:
                    </p>
                    <p
                      style={{
                        wordBreak: "auto-phrase", // Adjusts word breaking behavior
                        lineHeight: "1.6",
                      }}
                    >
                      <span className="font-semibold text-black">
                        • Recycled steel:
                      </span>{" "}
                      This reduces the need for mining and extraction of new
                      materials.
                    </p>
                    <p
                      style={{
                        wordBreak: "auto-phrase", // Adjusts word breaking behavior
                        lineHeight: "1.6",
                      }}
                    >
                      <span className="font-semibold text-black">
                        • Bamboo:
                      </span>{" "}
                      A fast-growing, renewable material, bamboo is used in
                      flooring and structural elements, reducing deforestation.
                    </p>
                    <p
                      style={{
                        wordBreak: "auto-phrase", // Adjusts word breaking behavior
                        lineHeight: "1.6",
                      }}
                    >
                      <span className="font-semibold text-black">
                        • Low-VOC paints and finishes:
                      </span>{" "}
                      These reduce harmful chemical emissions and improve indoor
                      air quality.
                    </p>
                    <p
                      style={{
                        wordBreak: "auto-phrase", // Adjusts word breaking behavior
                        lineHeight: "1.6",
                      }}
                    >
                      By opting for such materials, WIND GROUP reduces the
                      carbon footprint of their construction projects while
                      ensuring the buildings are durable, safe, and
                      aesthetically pleasing.
                    </p>
                    <h3 className="font-semibold">
                      2. Energy-Efficient Design
                    </h3>
                    <p
                      style={{
                        wordBreak: "auto-phrase", // Adjusts word breaking behavior
                        lineHeight: "1.6",
                      }}
                    >
                      Energy efficiency is one of the pillars of WIND GROUP’s
                      approach to sustainable real estate. They integrate the
                      latest technologies to reduce energy consumption,
                      including:
                    </p>
                    <p
                      style={{
                        wordBreak: "auto-phrase", // Adjusts word breaking behavior
                        lineHeight: "1.6",
                      }}
                    >
                      <span className="font-semibold text-black">
                        • Solar panels:
                      </span>{" "}
                      Harnessing solar energy is a key component in many WIND
                      GROUP projects. Solar panels are used to power common
                      areas in gated communities, as well as individual homes.
                    </p>
                    <p
                      style={{
                        wordBreak: "auto-phrase", // Adjusts word breaking behavior
                        lineHeight: "1.6",
                      }}
                    >
                      <span className="font-semibold text-black">
                        • LED lighting:{" "}
                      </span>{" "}
                      All buildings developed by WIND GROUP use energy-efficient
                      LED lighting systems that consume far less power than
                      traditional lighting options.
                    </p>
                    <p
                      style={{
                        wordBreak: "auto-phrase", // Adjusts word breaking behavior
                        lineHeight: "1.6",
                      }}
                    >
                      <span className="font-semibold text-black">
                        • Double-glazed windows:
                      </span>{" "}
                      To minimize heat transfer and reduce the need for heating
                      and cooling, WIND GROUP installs double-glazed windows in
                      homes and commercial spaces.
                    </p>
                    <p
                      style={{
                        wordBreak: "auto-phrase", // Adjusts word breaking behavior
                        lineHeight: "1.6",
                      }}
                    >
                      These energy-efficient designs not only benefit the
                      environment but also help homeowners save on utility
                      bills, making sustainable homes in Rewa more affordable in
                      the long run.
                    </p>
                    <h3 className="font-semibold">
                      3. Water Conservation Systems
                    </h3>
                    <p
                      style={{
                        wordBreak: "auto-phrase", // Adjusts word breaking behavior
                        lineHeight: "1.6",
                      }}
                    >
                      Water is a precious resource, and WIND GROUP ensures it is
                      used responsibly in all its projects.
                    </p>
                    <p
                      style={{
                        wordBreak: "auto-phrase", // Adjusts word breaking behavior
                        lineHeight: "1.6",
                      }}
                    >
                      <span className="font-semibold text-black">
                        • Rainwater harvesting:
                      </span>{" "}
                      This system collects rainwater and stores it for future
                      use, helping reduce reliance on municipal water supplies.
                    </p>
                    <p
                      style={{
                        wordBreak: "auto-phrase", // Adjusts word breaking behavior
                        lineHeight: "1.6",
                      }}
                    >
                      <span className="font-semibold text-black">
                        • Greywater recycling:
                      </span>{" "}
                      Recycled water from baths, sinks, and showers is reused
                      for non-potable purposes such as irrigation and toilet
                      flushing.
                    </p>
                    <p
                      style={{
                        wordBreak: "auto-phrase", // Adjusts word breaking behavior
                        lineHeight: "1.6",
                      }}
                    >
                      <span className="font-semibold text-black">
                        • Low-flow fixtures:
                      </span>{" "}
                      WIND GROUP equips homes with water-efficient taps,
                      showers, and toilets that reduce water wastage.
                    </p>
                    <p
                      style={{
                        wordBreak: "auto-phrase", // Adjusts word breaking behavior
                        lineHeight: "1.6",
                      }}
                    >
                      By integrating these water-saving technologies, WIND GROUP
                      is helping conserve water in a region where water scarcity
                      can often be an issue.
                    </p>
                    <h2>
                      How Sustainability Impacts Both the Environment and
                      Homebuyers
                    </h2>
                    <p>
                      The positive impact of eco-friendly construction extends
                      beyond environmental benefits—it directly influences the
                      lives of homeowners.
                    </p>
                    <h3 className="font-semibold">Environmental Benefits:</h3>
                    <p
                      style={{
                        wordBreak: "auto-phrase", // Adjusts word breaking behavior
                        lineHeight: "1.6",
                      }}
                    >
                      <span className="font-semibold text-black">
                        • Reduced carbon footprint:
                      </span>{" "}
                      By minimizing energy consumption and using green
                      materials, WIND GROUP’s projects contribute significantly
                      to reducing overall greenhouse gas emissions.
                    </p>
                    <p
                      style={{
                        wordBreak: "auto-phrase", // Adjusts word breaking behavior
                        lineHeight: "1.6",
                      }}
                    >
                      <span className="font-semibold text-black">
                        • Preserving natural ecosystems:
                      </span>{" "}
                      The responsible sourcing of materials ensures that natural
                      habitats and ecosystems are preserved during the
                      construction process.
                    </p>
                    <h3 className="font-semibold">Benefits for Homebuyers:</h3>
                    <p
                      style={{
                        wordBreak: "auto-phrase", // Adjusts word breaking behavior
                        lineHeight: "1.6",
                      }}
                    >
                      <span className="font-semibold text-black">
                        • Lower utility bills:
                      </span>{" "}
                      Energy-efficient homes significantly reduce electricity
                      and water bills, offering long-term savings to homeowners.
                    </p>
                    <p
                      style={{
                        wordBreak: "auto-phrase", // Adjusts word breaking behavior
                        lineHeight: "1.6",
                      }}
                    >
                      <span className="font-semibold text-black">
                        • Healthier living spaces:
                      </span>{" "}
                      Non-toxic materials, better air quality, and natural
                      lighting create healthier living environments, which is
                      especially beneficial for families.
                    </p>
                    <p
                      style={{
                        wordBreak: "auto-phrase", // Adjusts word breaking behavior
                        lineHeight: "1.6",
                      }}
                    >
                      <span className="font-semibold text-black">
                        • Higher property value:
                      </span>{" "}
                      As sustainability becomes more important, eco-friendly
                      homes tend to appreciate in value faster, making them a
                      better investment for the future.
                    </p>
                    <p
                      style={{
                        wordBreak: "auto-phrase", // Adjusts word breaking behavior
                        lineHeight: "1.6",
                      }}
                    >
                      With these benefits, WIND GROUP’s eco-friendly projects
                      not only serve the environment but also provide practical,
                      long-term advantages to homebuyers.
                    </p>
                    <h2>
                      Examples of WIND GROUP’s Sustainable Projects in Rewa
                    </h2>
                    <p>
                      WIND GROUP has undertaken several landmark projects in
                      Rewa that highlight their commitment to sustainability.
                      These projects are designed with an emphasis on green
                      living, energy efficiency, and community well-being.
                    </p>
                    <h3 className="font-semibold">
                      1. Gated Communities with Green Spaces
                    </h3>
                    <p>
                      WIND GROUP’s gated communities are designed to offer
                      residents a balance of luxury and sustainability. These
                      communities feature ample green spaces, including parks
                      and community gardens that promote healthy lifestyles
                      while contributing to environmental conservation.
                    </p>
                    <h3 className="font-semibold">
                      2. Premium Townships with Smart Technologies
                    </h3>
                    <p>
                      In addition to eco-friendly materials, WIND GROUP
                      integrates smart technologies in their townships to ensure
                      efficient use of energy and resources. Solar-powered
                      street lights, water management systems, and waste
                      recycling facilities are just a few examples of how these
                      townships offer both convenience and sustainability.
                    </p>
                  </>
                ) : selectedData.id == 2 ? (
                  <>
                    {" "}
                    <h2>
                      Why Customer-Centricity is Crucial in Real Estate
                      Development
                    </h2>
                    <p
                      style={{
                        wordBreak: "auto-phrase", // Adjusts word breaking behavior
                        lineHeight: "1.6",
                      }}
                    >
                      The real estate market is constantly evolving, with
                      clients expecting more transparency, flexibility, and
                      personalized experiences. In today’s fast-paced world,
                      customer-centric real estate development means putting the
                      customer at the core of every decision. For WIND GROUP,
                      this approach begins from the moment a potential client
                      shows interest in their properties.
                    </p>
                    <p
                      style={{
                        wordBreak: "auto-phrase", // Adjusts word breaking behavior
                        lineHeight: "1.6",
                      }}
                    >
                      By focusing on customer needs, preferences, and
                      expectations, WIND GROUP can tailor its projects to align
                      with what buyers are seeking—whether it’s a sustainable
                      home, a commercial space with modern amenities, or a gated
                      community with added security. This client-first mentality
                      ensures that every project is not only functional but also
                      desirable, enhancing the buyer's overall experience.
                    </p>
                    <h3 className="font-semibold">
                      The Value of Personalization
                    </h3>
                    <p
                      style={{
                        wordBreak: "auto-phrase", // Adjusts word breaking behavior
                        lineHeight: "1.6",
                      }}
                    >
                      One of the key reasons customer-centricity is so important
                      is because it fosters personalization. Real estate is a
                      major investment, and clients want to feel that their
                      needs are understood and met. WIND GROUP takes the time to
                      understand each client’s unique requirements, ensuring
                      that the properties they develop resonate with their
                      target audience.
                    </p>
                    <p
                      style={{
                        wordBreak: "auto-phrase", // Adjusts word breaking behavior
                        lineHeight: "1.6",
                      }}
                    >
                      For example, clients interested in eco-friendly living
                      will appreciate WIND GROUP’s focus on sustainable
                      construction technologies, while those seeking convenience
                      may value the company’s attention to detail in creating
                      accessible, well-planned townships.
                    </p>
                    <h2>
                      How WIND GROUP Uses Transparent Processes to Build Trust
                    </h2>
                    <p
                      style={{
                        wordBreak: "auto-phrase", // Adjusts word breaking behavior
                        lineHeight: "1.6",
                      }}
                    >
                      Transparency in real estate is one of the most important
                      factors for building trust with clients. The buying or
                      leasing process involves significant financial commitment,
                      and clients want to be confident that they are making the
                      right decision. WIND GROUP places a high emphasis on
                      maintaining open, honest communication with all their
                      clients throughout every phase of the real estate process.
                    </p>
                    <h3 className="font-semibold">
                      Clear Communication from Start to Finish
                    </h3>
                    <p
                      style={{
                        wordBreak: "auto-phrase", // Adjusts word breaking behavior
                        lineHeight: "1.6",
                      }}
                    >
                      From the initial consultation to the closing stages, WIND
                      GROUP ensures that clients are fully informed about the
                      progress of their project. Every step of the process is
                      explained clearly, from paperwork and contracts to
                      construction timelines and potential challenges. This
                      level of transparency helps to eliminate any
                      uncertainties, allowing clients to feel secure and
                      confident in their investments.
                    </p>
                    <p
                      style={{
                        wordBreak: "auto-phrase", // Adjusts word breaking behavior
                        lineHeight: "1.6",
                      }}
                    >
                      For WIND GROUP, transparency goes beyond just providing
                      information—it’s about being proactive and responsive to
                      client inquiries. Whether it’s answering questions about
                      the cost breakdown or discussing upcoming developments,
                      WIND GROUP makes sure its clients never feel left in the
                      dark.
                    </p>
                    <h2>
                      The Importance of Listening to and Addressing Customer
                      Needs
                    </h2>
                    <p
                      style={{
                        wordBreak: "auto-phrase", // Adjusts word breaking behavior
                        lineHeight: "1.6",
                      }}
                    >
                      At the core of customer-centric real estate development is
                      the ability to listen. WIND GROUP believes that the most
                      successful projects are the ones built with a deep
                      understanding of the customer’s vision. They actively
                      engage with clients to gather feedback, ask questions, and
                      understand their preferences before making any decisions
                      that impact the final outcome.
                    </p>
                    <p
                      style={{
                        wordBreak: "auto-phrase", // Adjusts word breaking behavior
                        lineHeight: "1.6",
                      }}
                    >
                      This commitment to listening extends beyond just the
                      initial stages of the project. WIND GROUP continues to
                      collaborate with clients throughout the construction
                      process, making adjustments as needed to ensure that the
                      final product aligns with their expectations. This
                      flexibility in property development demonstrates WIND
                      GROUP’s dedication to delivering what their clients truly
                      want, not just what is easiest or most profitable.
                    </p>
                    <h3 className="font-semibold">Anticipating Future Needs</h3>
                    <p
                      style={{
                        wordBreak: "auto-phrase", // Adjusts word breaking behavior
                        lineHeight: "1.6",
                      }}
                    >
                      WIND GROUP also takes a forward-thinking approach by
                      anticipating future trends and needs that customers may
                      not even realize they have. By staying ahead of industry
                      trends, WIND GROUP ensures that their developments remain
                      relevant and valuable to clients, even years down the
                      line.
                    </p>
                    <h2>
                      Client Testimonials Highlighting WIND GROUP’s Dedication
                      to Customer Satisfaction
                    </h2>
                    <p
                      style={{
                        wordBreak: "auto-phrase", // Adjusts word breaking behavior
                        lineHeight: "1.6",
                      }}
                    >
                      Real client testimonials provide a powerful insight into
                      how WIND GROUP’s customer-centric approach has made a
                      lasting impact. Here are a few testimonials from satisfied
                      clients that reflect WIND GROUP’s commitment to building
                      strong, trusting relationships:
                    </p>
                    <div className="font-bold text-black">
                      1. "From the moment I began working with WIND GROUP, I
                      felt heard and understood. They took the time to explain
                      every aspect of the property I was interested in and kept
                      me informed throughout the entire process. Their
                      transparency made me feel comfortable and confident in my
                      investment."
                    </div>
                    <div className="font-bold text-black">
                      2. "Mr. Manendra from WIND GROUP was exceptional in
                      helping us find the perfect property. He was always
                      available to answer questions and provided suggestions
                      that perfectly aligned with our needs. His attention to
                      detail and willingness to listen made us feel like valued
                      clients."
                    </div>
                    <div className="font-bold text-black">
                      3. "Working with Nitin at WIND GROUP was an absolute
                      pleasure. He was incredibly responsive and really listened
                      to what we were looking for in a plot. His greatest
                      strength was his ability to ask the right questions,
                      helping us realize exactly what we wanted."
                    </div>
                    <p
                      style={{
                        wordBreak: "auto-phrase", // Adjusts word breaking behavior
                        lineHeight: "1.6",
                      }}
                    >
                      These testimonials highlight the key attributes that
                      define WIND GROUP’s customer service: responsiveness,
                      attentiveness, and a deep commitment to fulfilling client
                      needs.
                    </p>
                    <h2>Building Lasting Relationships in Real Estate</h2>
                    <p
                      style={{
                        wordBreak: "auto-phrase", // Adjusts word breaking behavior
                        lineHeight: "1.6",
                      }}
                    >
                      Ultimately, WIND GROUP’s success as a real estate
                      developer is built on the relationships they cultivate
                      with their clients. By prioritizing customer-centric real
                      estate practices, such as transparency, communication, and
                      flexibility, WIND GROUP has established itself as a
                      trusted developer in Rewa. Their approach ensures that
                      clients don’t just invest in properties—they invest in a
                      relationship built on trust and understanding.
                    </p>
                  </>
                ) : (
                  <>
                    <h2>
                      WIND GROUP’s Vision for Rewa’s Urban Growth and
                      Development
                    </h2>
                    <p
                      style={{
                        wordBreak: "auto-phrase", // Adjusts word breaking behavior
                        lineHeight: "1.6",
                      }}
                    >
                      WIND GROUP’s vision for Rewa goes beyond mere
                      construction—it’s about transforming the city into a model
                      of modern urban living. As a reputed real estate
                      developer, WIND GROUP recognizes the growing demand for
                      sustainable living solutions, not just in terms of
                      eco-friendly construction but also in creating
                      well-planned communities that provide residents with
                      comfort, convenience, and a strong sense of belonging.
                    </p>
                    <p
                      style={{
                        wordBreak: "auto-phrase", // Adjusts word breaking behavior
                        lineHeight: "1.6",
                      }}
                    >
                      WIND GROUP envisions a Rewa where every resident has
                      access to thoughtfully designed homes, green spaces,
                      modern amenities, and robust infrastructure. Their
                      commitment to urban growth is evident in their approach to
                      community planning, where they focus on creating
                      self-sustaining ecosystems that cater to the needs of
                      present and future generations. By developing gated
                      communities, premium townships, and commercial projects,
                      WIND GROUP aims to elevate Rewa’s profile as a thriving
                      and sustainable urban center.
                    </p>
                    <h2>
                      The Impact of Gated Communities and Premium Townships on
                      Local Infrastructure
                    </h2>
                    <p
                      style={{
                        wordBreak: "auto-phrase", // Adjusts word breaking behavior
                        lineHeight: "1.6",
                      }}
                    >
                      One of the key areas where WIND GROUP is making a
                      significant impact is in the development of gated
                      communities and premium townships. These projects are not
                      only enhancing the quality of life for residents but are
                      also positively contributing to the city’s infrastructure.
                      Gated communities offer a secure, well-maintained
                      environment that includes essential facilities such as
                      24/7 security, power backup, green spaces, and modern
                      amenities like gyms, swimming pools, and shopping centers.
                    </p>
                    <p
                      style={{
                        wordBreak: "auto-phrase", // Adjusts word breaking behavior
                        lineHeight: "1.6",
                      }}
                    >
                      These developments are helping alleviate the pressure on
                      Rewa’s existing infrastructure by providing well-planned
                      spaces that incorporate essential services like waste
                      management, water supply, and road networks within the
                      community itself. Furthermore, by developing such
                      self-contained communities, WIND GROUP is reducing the
                      need for residents to rely on congested city centers for
                      daily necessities, thereby easing traffic and promoting a
                      more sustainable lifestyle.
                    </p>
                    <p
                      style={{
                        wordBreak: "auto-phrase", // Adjusts word breaking behavior
                        lineHeight: "1.6",
                      }}
                    >
                      WIND GROUP’s premium townships also play a vital role in
                      fostering economic growth by creating jobs, supporting
                      local businesses, and attracting investments to the area.
                      As more families and businesses move into these modern
                      developments, the surrounding areas benefit from improved
                      roads, enhanced public services, and the development of
                      retail and commercial spaces.
                    </p>
                    <h2>
                      Balancing Modernity and Sustainability in Real Estate
                      Development
                    </h2>
                    <p
                      style={{
                        wordBreak: "auto-phrase", // Adjusts word breaking behavior
                        lineHeight: "1.6",
                      }}
                    >
                      Sustainability is at the heart of WIND GROUP’s real estate
                      philosophy. In an era where environmental concerns are
                      becoming more pressing, WIND GROUP is committed to
                      constructing buildings that are not only modern and
                      luxurious but also eco-friendly and energy-efficient.
                    </p>{" "}
                    <p
                      style={{
                        wordBreak: "auto-phrase", // Adjusts word breaking behavior
                        lineHeight: "1.6",
                      }}
                    >
                      WIND GROUP’s developments incorporate a wide range of
                      sustainable technologies and practices, such as rainwater
                      harvesting systems, energy-efficient lighting, and the use
                      of environmentally friendly building materials. These
                      innovations help minimize the environmental impact of
                      construction while promoting long-term sustainability. By
                      adopting green building standards, WIND GROUP ensures that
                      their projects contribute to energy conservation and
                      reduced carbon emissions.
                    </p>{" "}
                    <p
                      style={{
                        wordBreak: "auto-phrase", // Adjusts word breaking behavior
                        lineHeight: "1.6",
                      }}
                    >
                      Additionally, WIND GROUP emphasizes the importance of
                      green spaces within their developments. Parks, gardens,
                      and landscaped areas are integrated into the design of
                      gated communities, providing residents with a healthy,
                      natural environment in which to live and play. These green
                      spaces not only enhance the aesthetic appeal of the
                      developments but also help in improving air quality and
                      promoting biodiversity in urban areas.
                    </p>{" "}
                    <p
                      style={{
                        wordBreak: "auto-phrase", // Adjusts word breaking behavior
                        lineHeight: "1.6",
                      }}
                    >
                      The balance between modernity and sustainability is what
                      sets WIND GROUP apart. While their projects feature the
                      latest in architectural design, technology, and luxury
                      amenities, they are also designed to be environmentally
                      responsible, catering to the needs of eco-conscious buyers
                      in Rewa.
                    </p>
                    <h2>
                      Upcoming Projects and Developments Shaping Rewa’s Future
                    </h2>
                    <p
                      style={{
                        wordBreak: "auto-phrase", // Adjusts word breaking behavior
                        lineHeight: "1.6",
                      }}
                    >
                      WIND GROUP’s forward-thinking approach is evident in its
                      upcoming projects, which promise to further transform
                      Rewa’s real estate landscape. Several high-profile
                      developments are in the pipeline, including new gated
                      communities and commercial spaces that are set to redefine
                      the way people live and work in the city.
                    </p>
                    <p
                      style={{
                        wordBreak: "auto-phrase", // Adjusts word breaking behavior
                        lineHeight: "1.6",
                      }}
                    >
                      One such project is a new premium township that will
                      feature a mix of residential homes, commercial complexes,
                      and recreational facilities. This integrated township will
                      offer residents a modern, sustainable living environment
                      with easy access to schools, healthcare facilities,
                      shopping centers, and public transportation. By creating
                      these self-sufficient communities, WIND GROUP is providing
                      a glimpse into the future of urban living in Rewa—one that
                      emphasizes convenience, connectivity, and environmental
                      responsibility.
                    </p>
                    <p
                      style={{
                        wordBreak: "auto-phrase", // Adjusts word breaking behavior
                        lineHeight: "1.6",
                      }}
                    >
                      In addition to residential projects, WIND GROUP is also
                      focusing on developing commercial landmarks that will
                      drive economic growth and attract businesses to the
                      region. These commercial spaces are designed with
                      state-of-the-art infrastructure and sustainability in
                      mind, ensuring that they meet the demands of modern
                      businesses while minimizing their environmental footprint.
                    </p>
                    <p
                      style={{
                        wordBreak: "auto-phrase", // Adjusts word breaking behavior
                        lineHeight: "1.6",
                      }}
                    >
                      As Rewa continues to grow, WIND GROUP’s developments will
                      play a crucial role in shaping the city’s future. With a
                      commitment to innovation, sustainability, and community,
                      WIND GROUP is leading the way in creating a vibrant,
                      modern, and eco-friendly urban environment.
                    </p>
                  </>
                )}

                <hr />
                <div className="row">
                  {selectedData.id == 1 ? (
                    <>
                      {" "}
                      <div className="col-lg-6">
                        <img src={newsFeed11} alt="Image" height={350} />
                        <label>{selectedData.name}</label>
                      </div>
                      <div className="col-lg-6">
                        <img src={newsFeed12} alt="Image" height={350} />
                      </div>
                    </>
                  ) : selectedData.id == 2 ? (
                    <>
                      {" "}
                      <div className="col-lg-6">
                        <img src={newsFeed21} alt="Image" height={350} />
                        <label>{selectedData.name}</label>
                      </div>
                      <div className="col-lg-6">
                        <img src={newsFeed22} alt="Image" height={350} />
                      </div>
                    </>
                  ) : (
                    <>
                      {" "}
                      <div className="col-lg-6">
                        <img src={newsFeed31} alt="Image" height={350} />
                        <label>{selectedData.name}</label>
                      </div>
                      <div className="col-lg-6">
                        <img src={newsFeed32} alt="Image" height={350} />
                      </div>
                    </>
                  )}
                </div>
                <h2>Conclusion</h2>
                <p>
                  {selectedData.id == 1 ? (
                    <>
                      {" "}
                      As one of Rewa’s leading real estate developers, WIND
                      GROUP is setting new standards for sustainable real estate
                      development. By incorporating green building technologies,
                      eco-friendly materials, and energy-efficient designs, WIND
                      GROUP is not just building homes—they are building a
                      greener future. Their projects, from gated communities to
                      premium townships, exemplify the shift towards
                      eco-friendly living, providing lasting benefits for both
                      the environment and residents.
                    </>
                  ) : selectedData.id == 2 ? (
                    <>
                      In the world of real estate, the importance of
                      customer-centricity cannot be overstated. WIND GROUP’s
                      dedication to client-focused property development,
                      transparent processes, and active listening has set them
                      apart as a leader in the industry. Whether it’s a
                      residential project or a commercial space, WIND GROUP’s
                      commitment to understanding and meeting the needs of their
                      clients ensures that they continue to build relationships
                      that last.
                    </>
                  ) : (
                    <>
                      WIND GROUP’s vision for transforming Rewa into a hub for
                      modern, sustainable, and connected communities is coming
                      to life through their innovative real estate projects. By
                      focusing on urban growth, enhancing local infrastructure,
                      and striking a balance between modernity and
                      sustainability, WIND GROUP is shaping a future where
                      Rewa’s residents can enjoy a higher quality of life in
                      harmony with the environment.
                    </>
                  )}
                </p>
                <p>
                  {selectedData.id == 1 ? (
                    <>
                      {" "}
                      With WIND GROUP, sustainable real estate is no longer a
                      luxury—it’s a standard. Embrace the future of eco-friendly
                      living in Rewa with WIND GROUP’s pioneering approach to
                      real estate development.
                    </>
                  ) : selectedData.id == 3 ? (
                    <>
                      As they continue to develop gated communities, premium
                      townships, and commercial landmarks, WIND GROUP’s impact
                      on Rewa’s real estate landscape will be felt for
                      generations to come. Their projects not only contribute to
                      the city’s economic development but also reflect a
                      commitment to creating sustainable, modern communities
                      that are built to last.
                    </>
                  ) : (
                    <></>
                  )}
                </p>

                <hr />

                {/* Previous and Next Buttons */}
                <div className="ltn__prev-next-btn row mb-50">
                  <div className="blog-prev col-lg-6">
                    <h6>Prev Post</h6>
                    <h3 className="ltn__blog-title">
                      <Link to={relatedPost[0].link}>
                        {relatedPost[0].name}
                      </Link>
                    </h3>
                  </div>
                  <div className="blog-next text-right col-lg-6">
                    <h6>Next Post</h6>
                    <h3 className="ltn__blog-title">
                      <Link to={relatedPost[1].link}>
                        {relatedPost[1].name}
                      </Link>
                    </h3>
                  </div>
                </div>
                <hr />

                {/* Related Posts */}
                <div className="related-post-area mb-50">
                  <h4 className="title-2">Related Post</h4>
                  <div className="row">
                    {relatedPost.map((post) => (
                      <div className="col-md-6" key={post.id}>
                        <div className="ltn__blog-item ltn__blog-item-6">
                          <div>
                            <Link to={post.link}>
                              <img src={post.img} alt="Image" />
                            </Link>
                          </div>
                          <div
                            className="ltn__blog-brief"
                            style={{ minHeight: "270px" }}
                          >
                            <div className="ltn__blog-meta">
                              <ul>
                                <li className="ltn__blog-date ltn__secondary-color">
                                  <i className="far fa-calendar-alt" />
                                  {post.date}
                                </li>
                              </ul>
                            </div>
                            <h3 className="ltn__blog-title">
                              <Link to={post.link}>{post.name}</Link>
                            </h3>
                            <p>{post.subName}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Sidebar />
        </div>
      </div>
    </div>
  );
};

export default BlogDetails;
