import React from "react";
import Footer from "../component/footer";
import Navbar from "../component/navbar";
import PageHeader from "../component/Page-header";
import RealEstateDetails from "../component/realEstate";

const RealEstate = () => {
  return (
    <div>
      <Navbar CustomClass="ltn__header-transparent gradient-color-2" />
      <PageHeader headertitle="Service" subheader="Real Estate Development" />
      <RealEstateDetails/>
      <Footer />
    </div>
  );
};

export default RealEstate;
