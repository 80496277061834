import React from "react";

const Map = () => {
  return (
    <div className="google-map mb-1">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d17264.23701739104!2d81.29809826554714!3d24.542261721123914!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39845a348b85dbbd%3A0xbc368e68a40a6da9!2sRewa%2C%20Madhya%20Pradesh!5e0!3m2!1sen!2sin!4v1728364233459!5m2!1sen!2sin"
        width="100%"
        height="100%"
        frameBorder={0}
        allowFullScreen
        aria-hidden="false"
        tabIndex={0}
      />
    </div>
  );
};

export default Map;
