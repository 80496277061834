import React from "react";
import Footer from "../component/footer";
import Navbar from "../component/navbar";
import PageHeader from "../component/Page-header";
import ContactInfo from "../component/contactInfo";
import ContactForm from "../component/contactForm";
import Map from "../component/map";

const Contact = () => {
  return (
    <div>
      <Navbar CustomClass="ltn__header-transparent gradient-color-2" />
      <PageHeader headertitle="Contact Us" subheader="Contact" />
      <ContactInfo />
      <ContactForm />
      <Map />
      <Footer />
    </div>
  );
};

export default Contact;
