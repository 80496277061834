import React from "react";
import BlogSlider from "../component/blogSlider";
import Footer from "../component/footer";
import Navbar from "../component/navbar";
import PageHeader from "../component/Page-header";
import Testimonial from "../component/testimonial";
import AboutUsCard from "../component/aboutUsCard";

const About = () => {
  return (
    <div>
      <Navbar CustomClass="ltn__header-transparent gradient-color-2" />
      <PageHeader headertitle="About us" subheader="about" />
      <AboutUsCard />
      {/* <Team /> */}
      <Testimonial />
      <BlogSlider customClass="section-subtitle-2" />
      <Footer />
    </div>
  );
};

export default About;
