import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import logo from "../assets/img/logo2.png";
import newsFeed1 from "../assets/img/newsFeed1.png";
import newsFeed2 from "../assets/img/newsFeed2.png";
import newsFeed3 from "../assets/img/newsFeed3.png";
import plotSiteLayout from "../assets/img/plotDetails.png";
import { getAllPlot, getMainPlot } from "../service/axiosInstance";
import $ from "jquery"; // Ensure jQuery is available
import "slick-carousel"; // Make sure Slick is loaded

const ProductDetails = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [data1, setData1] = useState([]);
  const [isSliderInitialized, setIsSliderInitialized] = useState(false); // Track slider initialization state
  const [isSliderInitialized1, setIsSliderInitialized1] = useState(false); // Track slider initialization state

  const sliderRef = useRef(null); // Ref to access the slider container
  const sliderRef1 = useRef(null);

  let totalSubplots = 0;
  let minPrice = Infinity;
  let maxPrice = -Infinity;
  let minSize = Infinity;
  let maxSize = -Infinity;

  const data2 = [
    {
      id: 1,
      img: newsFeed1,
      name: "Sustainable Real Estate",
      subName:
        "Sustainable Real Estate: How WIND GROUP is Pioneering Eco-Friendly Construction in Rewa",
      date: "October 22, 2024",
      link: "/blog-details/1",
    },
    {
      id: 2,
      img: newsFeed2,
      name: "The Role of Customer-Centricity in Real Estate",
      subName:
        "The Role of Customer-Centricity in Real Estate: How WIND GROUP Builds Relationships That Last",
      date: "September 19, 2024",
      link: "/blog-details/2",
    },
    {
      id: 3,
      img: newsFeed3,
      name: "Transforming Rewa",
      subName:
        "Transforming Rewa: WIND GROUP’s Vision for Modern, Sustainable, and Connected Communities",
      date: "November 27, 2024",
      link: "/blog-details/3",
    },
  ];

  // Initialize counters for available, sold, and booked plots
  let availableCount = 0;
  let soldCount = 0;
  let bookedCount = 0;

  // To store the unique categories of subplots
  const categories = new Set();

  if (data) {
    data?.blocks?.forEach((block) => {
      totalSubplots += block.sub_plot.length;
      block.sub_plot.forEach((subPlot) => {
        minPrice = Math.min(minPrice, subPlot.price);
        maxPrice = Math.max(maxPrice, subPlot.price);

        const sizeInSqFt = parseInt(subPlot.size.split(" ")[0], 10); // Extract size from "100 sq ft"
        minSize = Math.min(minSize, sizeInSqFt);
        maxSize = Math.max(maxSize, sizeInSqFt);
      });
    });

    // Iterate over the blocks and subplots to count the available, sold, booked plots and gather categories
    data?.blocks?.forEach((block) => {
      block.sub_plot.forEach((subPlot) => {
        // Check the availability status and increment the appropriate counter
        if (subPlot.availability_status === "available") {
          availableCount++;
        } else if (subPlot.availability_status === "sold") {
          soldCount++;
        } else if (subPlot.availability_status === "booked") {
          bookedCount++;
        }

        // Check if there are any installments to consider it as booked
        // if (subPlot.booking.installments.length > 0) {

        // }

        // Add the category to the set of categories
        categories.add(subPlot.category);
      });
    });
  }

  // Convert the set to an array
  const uniqueCategories = Array.from(categories);
  if (uniqueCategories) {
    console.log(uniqueCategories.join(","));
  }

  const { id } = useParams();

  const getMainPlotData = async () => {
    try {
      const resp = await getMainPlot(id);
      if (resp.status == 200) {
        setData(resp.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllMainPlot = async () => {
    try {
      const resp = await getAllPlot({
        page: 1,
        limit: 5,
      });
      setData1(resp.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getMainPlotData();
    getAllMainPlot();
  }, []);

  useEffect(() => {
    getMainPlotData();

    // Initialize the Slick slider only when data is available
    const $slider = $(sliderRef.current);

    const initializeSlider = () => {
      if ($slider && $slider.length && data?.media?.images.length > 0) {
        // Destroy the previous slider instance if it exists
        if ($slider.hasClass("slick-initialized")) {
          $slider.slick("unslick");
        }

        // Initialize the slider
        $slider.slick({
          rtl: false,
          arrows: true,
          dots: false,
          infinite: true,
          speed: 300,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "450px",
          prevArrow:
            '<a class="slick-prev"><i class="fas fa-arrow-left" alt="Arrow Icon"></i></a>',
          nextArrow:
            '<a class="slick-next"><i class="fas fa-arrow-right" alt="Arrow Icon"></i></a>',
          responsive: [
            {
              breakpoint: 1600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: true,
                centerPadding: "250px",
              },
            },
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: true,
                centerPadding: "200px",
              },
            },
            {
              breakpoint: 992,
              settings: {
                arrows: false,
                dots: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: true,
                centerPadding: "150px",
              },
            },
            {
              breakpoint: 768,
              settings: {
                arrows: false,
                dots: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: false,
                centerPadding: "0px",
              },
            },
            {
              breakpoint: 580,
              settings: {
                arrows: false,
                dots: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: false,
                centerPadding: "0px",
              },
            },
          ],
        });
      }
      setIsSliderInitialized(true);
    };

    // Initialize the slider when data is loaded
    if (data?.media?.images.length > 0) {
      initializeSlider();
    }

    // Cleanup on component unmount
    return () => {
      if ($slider && $slider.hasClass("slick-initialized")) {
        // $slider.slick("unslick");
      }
      setIsSliderInitialized(false);
    };
  }, [data?.media?.images.length, isSliderInitialized]); // Depend on data to re-initialize if needed

  useEffect(() => {
    getAllMainPlot();

    // Initialize the Slick slider only when data is available
    const $slider = $(sliderRef1.current);

    const initializeSlider = () => {
      if ($slider && $slider.length && data1.length > 0) {
        // Destroy the previous slider instance if it exists
        if ($slider.hasClass("slick-initialized")) {
          $slider.slick("unslick");
        }

        // Initialize the slider
        $slider.slick({
          arrows: false,
          dots: true,
          infinite: true,
          speed: 300,
          slidesToShow: 1,
          slidesToScroll: 1,
          prevArrow:
            '<a class="slick-prev"><i class="fas fa-arrow-left" alt="Arrow Icon"></i></a>',
          nextArrow:
            '<a class="slick-next"><i class="fas fa-arrow-right" alt="Arrow Icon"></i></a>',
        });
      }
      setIsSliderInitialized1(true);
    };

    // Initialize the slider when data is loaded
    if (data1.length > 0) {
      initializeSlider();
    }

    // Cleanup on component unmount
    return () => {
      if ($slider && $slider.hasClass("slick-initialized")) {
        // $slider.slick("unslick");
      }
      setIsSliderInitialized1(false);
    };
  }, [data1.length, isSliderInitialized1]); // Depend on data to re-initialize if needed

  return (
    <>
      <div className="ltn__img-slider-area mb-90">
        <div className="container-fluid">
          <div
            className="row ltn__image-slider-5-active slick-arrow-1 slick-arrow-1-inner ltn__no-gutter-all"
            ref={sliderRef}
          >
            {data &&
              data?.media?.images?.map((e) => {
                return (
                  <div className="col-lg-12">
                    <div className="ltn__img-slide-item-4">
                      <a href={e} data-rel="lightcase:myCollection">
                        <img src={e} alt="Image" height={650} />
                      </a>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <div className="ltn__shop-details-area pb-10">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="ltn__shop-details-inner ltn__page-details-inner mb-30">
                <div className="ltn__blog-meta">
                  <ul>
                    <li className="ltn__blog-category">
                      <Link className="bg-orange" to="#">
                        For Booking
                      </Link>
                    </li>
                    <li className="ltn__blog-date">
                      <i className="far fa-calendar-alt" />
                      {new Date(data?.createdAt).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}
                    </li>
                  </ul>
                </div>
                <h1>{data?.name}</h1>
                <label>
                  <span className="ltn__secondary-color">
                    <i className="flaticon-pin" />
                  </span>{" "}
                  {data?.location?.area}, {data?.location?.state}
                </label>
                <h4 className="title-2">Description</h4>
                <p>{data?.description}</p>
                <h4 className="title-2">Property Detail</h4>
                <div className="property-detail-info-list section-bg-1 clearfix mb-30">
                  <ul>
                    <li>
                      <label>Blocks:</label> <span>{data?.blocks?.length}</span>
                    </li>
                    <li>
                      <label>Sub Plots:</label> <span>{totalSubplots}</span>
                    </li>
                    <li>
                      <label>Price Range:</label>{" "}
                      <span>
                        {" "}
                        {minPrice == maxPrice ? (
                          <>₹ {minPrice}</>
                        ) : (
                          <>
                            ₹ {minPrice} - ₹ {maxPrice}
                          </>
                        )}
                      </span>
                    </li>
                    <li>
                      <label>Area:</label> <span>{data?.location?.area} </span>
                    </li>
                    <li>
                      <label>Dimensions:</label>{" "}
                      <span>
                        {" "}
                        {minSize == maxSize ? (
                          <> {minSize}</>
                        ) : (
                          <>
                            {minSize} - {maxSize}
                          </>
                        )}{" "}
                        sqft
                      </span>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <label>Available Plots:</label>{" "}
                      <span>{availableCount}</span>
                    </li>
                    <li>
                      <label>Sold Plots:</label> <span>{soldCount}</span>
                    </li>
                    <li>
                      <label>Book Plots:</label> <span>{bookedCount}</span>
                    </li>
                    <li>
                      <label>Category:</label>{" "}
                      <span>{uniqueCategories.join(", ")}</span>
                    </li>
                    <li>
                      <label>Year built:</label>{" "}
                      <span>
                        {" "}
                        {new Date(data?.createdAt).toLocaleDateString("en-US", {
                          year: "numeric",
                        })}
                      </span>
                    </li>
                  </ul>
                </div>
                <h4 className="title-2">From Our Gallery</h4>
                <div className="ltn__property-details-gallery mb-30">
                  <div className="row">
                    <div className="col-md-12">
                      {data &&
                        data?.media?.images?.map((e) => {
                          return (
                            <a href={e} data-rel="lightcase:myCollection">
                              <img
                                className="mb-30 w-[48%] mx-2"
                                src={e}
                                alt="Image"
                              />
                            </a>
                          );
                        })}
                    </div>
                  </div>
                </div>
                <h4 className="title-2 mb-10">Amenities</h4>
                <div className="property-details-amenities mb-30">
                  <div className="row">
                    <div className="col-lg-4 col-md-6">
                      <div className="ltn__menu-widget">
                        <ul>
                          {data?.amenities?.map((e) => {
                            return (
                              <li>
                                <label className="checkbox-item">
                                  {e}
                                  <input
                                    type="checkbox"
                                    defaultChecked="checked"
                                  />
                                  <span className="checkmark" />
                                </label>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <h4 className="title-2">Location</h4>
                <div className="property-details-google-map mb-30">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d17264.23701739104!2d81.29809826554714!3d24.542261721123914!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39845a348b85dbbd%3A0xbc368e68a40a6da9!2sRewa%2C%20Madhya%20Pradesh!5e0!3m2!1sen!2sin!4v1728364233459!5m2!1sen!2sin"
                    width="100%"
                    height="100%"
                    frameBorder={0}
                    allowFullScreen
                    aria-hidden="false"
                    tabIndex={0}
                  />
                </div>
                <h4 className="title-2">Plot Site Layout</h4>
                {/* APARTMENTS PLAN AREA START */}
                <div className="ltn__apartments-plan-area product-details-apartments-plan mb-30">
                  <div className="tab-content">
                    <div
                      className="tab-pane fade active show"
                      id="liton_tab_3_1"
                    >
                      <div className="ltn__apartments-tab-content-inner">
                        <div className="row">
                          <div className="col-lg-6">
                            <div
                              className="apartments-plan-info-1 ltn__secondary-bg text-color-white"
                              style={{
                                backgroundColor: "#262626",
                                minHeight: "700px",
                              }}
                            >
                              <h2>{data?.name}</h2>
                              <p className="line-clamp-6">
                                {data?.description}
                              </p>
                              <Link to="/contact">
                                <i className="flaticon-pin" />
                                <span className="text-lg mx-2">
                                  {" "}
                                  {data?.location?.area},{" "}
                                  {data?.location?.state}
                                </span>
                              </Link>
                              <div className="mt-2">
                                Price : ₹ {minPrice} - ₹ {maxPrice}
                              </div>
                              <div className="apartments-info-list apartments-info-list-color mt-30">
                                <ul>
                                  <li>
                                    <label
                                      style={{ backgroundColor: "#262626" }}
                                    >
                                      Total Area
                                    </label>{" "}
                                    <span
                                      style={{ backgroundColor: "#262626" }}
                                    >
                                      {minSize}-{maxSize} Square ft
                                    </span>
                                  </li>

                                  <li>
                                    <label
                                      style={{ backgroundColor: "#262626" }}
                                    >
                                      No of Plots
                                    </label>{" "}
                                    <span
                                      style={{ backgroundColor: "#262626" }}
                                    >
                                      {totalSubplots}
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="apartments-plan-img flex justify-center">
                              <div>
                                {/* Thumbnail Image that Opens Modal */}
                                <img
                                  src={plotSiteLayout}
                                  alt={data?.name}
                                  height={700}
                                  width={500}
                                  className="cursor-pointer transition-transform transform hover:scale-110"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* APARTMENTS PLAN AREA END */}
                <h4 className="title-2">Property Video</h4>
                <div
                  className="ltn__video-bg-img ltn__video-popup-height-500 bg-overlay-black-50 bg-image mb-30"
                  data-bs-bg={data?.media?.images[1]}
                >
                  <a
                    className="ltn__video-icon-2 ltn__video-icon-2-border---"
                    href={data?.media?.videos[0]}
                    data-rel="lightcase:myCollection"
                  >
                    <i className="fa fa-play" />
                  </a>
                </div>

                <div className="text-right">
                  <button
                    type="submit"
                    style={{ borderRadius: "2px" }}
                    className="btn theme-btn-1"
                    onClick={() =>
                      window.open("https://app.windnxsmart.com/login", "_blank")
                    }
                  >
                    Book Now
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <aside className="sidebar ltn__shop-sidebar ltn__right-sidebar---">
                {/* Popular Product Widget */}
                <div className="widget ltn__popular-product-widget">
                  <h4 className="ltn__widget-title ltn__widget-title-border-2">
                    Popular Properties
                  </h4>
                  <div
                    className="row ltn__popular-product-widget-active slick-arrow-1"
                    ref={sliderRef1}
                  >
                    {/* ltn__product-item */}
                    {data1.map((element) => {
                      let totalSubplots = 0;
                      let minPrice = Infinity;
                      let maxPrice = -Infinity;
                      let minSize = Infinity;
                      let maxSize = -Infinity;
                      element.blocks.forEach((block) => {
                        totalSubplots += block.sub_plot.length;
                        block.sub_plot.forEach((subPlot) => {
                          minPrice = Math.min(minPrice, subPlot.price);
                          maxPrice = Math.max(maxPrice, subPlot.price);

                          const sizeInSqFt = parseInt(
                            subPlot.size.split(" ")[0],
                            10
                          ); // Extract size from "100 sq ft"
                          minSize = Math.min(minSize, sizeInSqFt);
                          maxSize = Math.max(maxSize, sizeInSqFt);
                        });
                      });
                      return (
                        <div className="col-12">
                          <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
                            <div className="product-img">
                              <Link to={`/product-details/${element._id}`}>
                                <img src={element.media.images[0]} alt="#" />
                              </Link>
                              <div className="real-estate-agent">
                                <div className="agent-img">
                                  {/* <Link to="/team-details"> */}
                                  <img src={logo} alt="#" />
                                  {/* </Link> */}
                                </div>
                              </div>
                            </div>
                            <div className="product-info">
                              <div className="product-price">
                                <span>
                                  {" "}
                                  {minPrice == maxPrice ? (
                                    <>₹ {minPrice}</>
                                  ) : (
                                    <>
                                      ₹ {minPrice} - ₹ {maxPrice}
                                    </>
                                  )}
                                </span>
                              </div>
                              <h2 className="product-title">
                                <Link to={`/product-details/${element._id}`}>
                                  {element.name}
                                </Link>
                              </h2>
                              <div className="product-img-location">
                                <ul>
                                  <li>
                                    <Link
                                      to={`/product-details/${element._id}`}
                                    >
                                      <i className="flaticon-pin" />{" "}
                                      {element.location.area},{" "}
                                      {element.location.state}
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                              <ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
                                <li>
                                  <span>{element.blocks.length} </span> Blocks
                                </li>
                                <li>
                                  <span>{totalSubplots}</span> Sub Plots
                                </li>
                                <li>
                                  <span>
                                    {" "}
                                    {minSize == maxSize ? (
                                      <> {minSize}</>
                                    ) : (
                                      <>
                                        {minSize} - {maxSize}
                                      </>
                                    )}{" "}
                                  </span>
                                  square Ft
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    {/*  */}
                  </div>
                </div>
                {/* Popular Post Widget */}
                <div className="widget ltn__popular-post-widget">
                  <h4 className="ltn__widget-title ltn__widget-title-border-2">
                    Leatest Blogs
                  </h4>
                  <ul>
                    {data2.map((e) => (
                      <li>
                        <div className="popular-post-widget-item clearfix">
                          <div className="popular-post-widget-img">
                            <Link to={e.link}>
                              <img src={e.img} alt="#" />
                            </Link>
                          </div>
                          <div className="popular-post-widget-brief">
                            <h6>
                              <Link to={e.link}>{e.name}</Link>
                            </h6>
                            <div className="ltn__blog-meta">
                              <ul>
                                <li className="ltn__blog-date">
                                  <i className="far fa-calendar-alt" />
                                  {e.date}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductDetails;
