import React from "react";
import { Link } from "react-router-dom";
import plot_copy from "../assets/img/plot_copy.jpg";
import { useTranslation } from "react-i18next";

const FaqV1 = () => {
  const { t } = useTranslation();

  return (
    <div className="ltn__faq-area mb-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="ltn__faq-inner ltn__faq-inner-2">
              <div id="accordion_2">
                {[1, 2, 3, 4, 5, 6].map((item) => (
                  <div className="card" key={item}>
                    <h6
                      className="ltn__card-title"
                      data-bs-toggle="collapse"
                      data-bs-target={`#faq-item-2-${item}`}
                      aria-expanded={item === 2}
                    >
                      {t(`faq.title${item}`)}
                    </h6>
                    <div
                      id={`faq-item-2-${item}`}
                      className={`collapse ${item === 2 ? "show" : ""}`}
                      data-bs-parent="#accordion_2"
                      style={{ visibility: "visible" }}
                    >
                      <div className="card-body">
                        <p>{t(`faq.content${item}`)}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="need-support text-center mt-100">
                <h2>{t("faq.support")}</h2>
                <div className="btn-wrapper mb-30 go-top">
                  <Link to="/contact" className="theme-btn-1 btn">
                    {t("faq.contact")}
                  </Link>
                </div>
                <h3>
                  <i className="fas fa-phone" /> +07662-469-318
                </h3>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <aside className="sidebar-area ltn__right-sidebar">
              <div className="widget ltn__banner-widget go-top">
                <Link to="shop.html">
                  <img
                    src={plot_copy}
                    alt="Banner Image"
                    width={500}
                    height={520}
                  />
                </Link>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqV1;
