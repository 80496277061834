import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import newsFeed1 from "../assets/img/newsFeed1.png";
import newsFeed2 from "../assets/img/newsFeed2.png";
import newsFeed3 from "../assets/img/newsFeed3.png";
import { getAllPlot } from "../service/axiosInstance";
import logo from "../assets/img/logo2.png";
import Select from "react-select";
import $ from "jquery"; // Ensure jQuery is available
import "slick-carousel"; // Make sure Slick is loaded
import { useFormik } from "formik";
import * as Yup from "yup";
import { createLeads } from "../service/axiosInstance";
import { errorToast, successToast } from "../toastConfig";

const Sidebar = () => {
  const navigate = useNavigate();
  const [isSliderInitialized1, setIsSliderInitialized1] = useState(false); // Track slider initialization state
  const sliderRef1 = useRef(null);
  const data = [
    {
      id: 1,
      img: newsFeed1,
      name: "Sustainable Real Estate",
      subName:
        "Sustainable Real Estate: How WIND GROUP is Pioneering Eco-Friendly Construction in Rewa",
      date: "October 22, 2024",
      link: "/blog-details/1",
    },
    {
      id: 2,
      img: newsFeed2,
      name: "The Role of Customer-Centricity in Real Estate",
      subName:
        "The Role of Customer-Centricity in Real Estate: How WIND GROUP Builds Relationships That Last",
      date: "September 19, 2024",
      link: "/blog-details/2",
    },
    {
      id: 3,
      img: newsFeed3,
      name: "Transforming Rewa",
      subName:
        "Transforming Rewa: WIND GROUP’s Vision for Modern, Sustainable, and Connected Communities",
      date: "November 27, 2024",
      link: "/blog-details/3",
    },
  ];

  const options = [
    { value: "Plot Related", label: "Plot Related" },
    { value: "Career", label: "Career" },
    { value: "Enquiry", label: "Enquiry" },
    { value: "Payment Issue", label: "Payment Issue" },
    { value: "Other", label: "Other" },
  ];

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border:
        state.isFocused || state.isHovered
          ? "1px solid #e7c44f"
          : "2px solid #e5eaee",
      boxShadow: state.isFocused ? "none" : "none", // Removes the default focus shadow
      borderRadius: "0",
      height: "65px",
      fontSize: "14px",
      fontWeight: "700",
      lineHeight: "60px",
      width: "100%",
      marginBottom:
        formik.touched.service && formik.errors.service ? "10px" : "25px",
      "&:hover": {
        border: "1px solid #e7c44f", // Border color on hover
        boxShadow: "none", // No default shadow on hover
      },
      position: "relative", // Position context for the dropdown indicator
      display: "flex",
      alignItems: "center", // Aligns text and icon vertically
      justifyContent: "space-between", // Positions the icon to the right
      cursor: "pointer", // Change cursor to indicate interactivity
      color: "#6B7F89",
      paddingBottom: "20px",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontWeight: "700",
      paddingBottom: "20px",
    }),
    indicatorSeparator: (provided) => ({
      display: "none",
      paddingBottom: "45px",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#e7c44f",
      paddingBottom: "45px",
    }),
    menuList: (provided) => ({
      ...provided,
      backgroundColor: "white", // Set background for the options menu
      zIndex: 1,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor:
        state.isFocused || state.isSelected
          ? "#f6f6f6"
          : state.isHovered
          ? "#f1f1f1"
          : "white", // Grey on hover
      color: state.isFocused || state.isSelected ? "#647984" : "#6B7F89", // Change text color on hover or selection
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#f1f1f1", // Grey background for hovered option
      },
      zIndex: 9999999999999,
      paddingLeft: "20px",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#888", // Placeholder text color
      paddingBottom: "20px",
    }),

    valueContainer: (provided) => ({
      ...provided,
      color: "#6B7F89",
      paddingLeft: "15px", // Add padding to the left for the selected value
      paddingBottom: "20px",
    }),
  };

  const [data1, setData1] = useState([]);

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    phone: Yup.string()
      .required("Phone number is required")
      .matches(/^\d{10}$/, "Phone number must be exactly 10 digits"),
    service: Yup.string().required("Please select a service"),
    message: Yup.string().required("Message is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      service: "",
      message: "",
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      handleSubmit(values, resetForm);
    },
  });

  const handleSubmit = async (values, resetForm) => {
    try {
      const data = {
        customer_info: {
          name: values.name,
          phone: values.phone,
          Preference: "website",
        },
        leads_source: values.service,
        remarks: values.message,
        interaction: "call",
      };
      const res = await createLeads(data);
      if (res.status == 201) {
        successToast("Your Form has been Submitted");
        resetForm();
      }
    } catch (error) {
      errorToast(error.message);
    }
  };

  const getAllMainPlot = async () => {
    try {
      const resp = await getAllPlot({
        page: 1,
        limit: 3,
      });
      setData1(resp.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllMainPlot();
  }, []);

  useEffect(() => {
    getAllMainPlot();

    // Initialize the Slick slider only when data is available
    const $slider = $(sliderRef1.current);

    const initializeSlider = () => {
      if ($slider && $slider.length && data1.length > 0) {
        // Destroy the previous slider instance if it exists
        if ($slider.hasClass("slick-initialized")) {
          $slider.slick("unslick");
        }

        // Initialize the slider
        $slider.slick({
          arrows: false,
          dots: true,
          infinite: true,
          speed: 300,
          slidesToShow: 1,
          slidesToScroll: 1,
          prevArrow:
            '<a class="slick-prev"><i class="fas fa-arrow-left" alt="Arrow Icon"></i></a>',
          nextArrow:
            '<a class="slick-next"><i class="fas fa-arrow-right" alt="Arrow Icon"></i></a>',
        });
      }
      setIsSliderInitialized1(true);
    };

    // Initialize the slider when data is loaded
    if (data1.length > 0) {
      initializeSlider();
    }

    // Cleanup on component unmount
    return () => {
      if ($slider && $slider.hasClass("slick-initialized")) {
        // if (typeof $slider.off === "function") {
        //   $slider.off(); // Remove event listeners (if applied via jQuery)
        // }
        // $slider.slick("unslick");
      }
      setIsSliderInitialized1(false);
    };
  }, [data1.length, isSliderInitialized1]); // Depend on data to re-initialize if needed

  return (
    <div className="col-lg-4 go-top">
      <aside className="sidebar-area blog-sidebar ltn__right-sidebar">
        {/* Form Widget */}
        <div className="widget ltn__form-widget">
          <h4 className="ltn__widget-title ltn__widget-title-border-2">
            Drop Messege For Book
          </h4>
          <form onSubmit={formik.handleSubmit}>
            <input
              type="text"
              name="name"
              placeholder="Enter your name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              style={
                formik.touched.name && formik.errors.name
                  ? { marginBottom: "10px" }
                  : {}
              }
            />
            {formik.touched.name && formik.errors.name ? (
              <div className="text-red-500 text-sm mb-3">
                {formik.errors.name}
              </div>
            ) : null}
            <input
              type="email"
              name="email"
              placeholder="Enter email address"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              style={
                formik.touched.email && formik.errors.email
                  ? { marginBottom: "10px" }
                  : {}
              }
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="text-red-500 text-sm mb-3">
                {formik.errors.email}
              </div>
            ) : null}
            <input
              type="text"
              name="phone"
              placeholder="Enter phone number"
              value={formik.values.phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              style={
                formik.touched.phone && formik.errors.phone
                  ? { marginBottom: "10px" }
                  : {}
              }
            />
            {formik.touched.phone && formik.errors.phone ? (
              <div className="text-red-500 text-sm mb-3">
                {formik.errors.phone}
              </div>
            ) : null}
            <Select
              name="service"
              value={options.find(
                (option) => option.value === formik.values.service
              )}
              onChange={(option) =>
                formik.setFieldValue("service", option.value)
              }
              options={options}
              styles={customStyles}
              placeholder="Select Service Type"
              isSearchable={false} // Disabling the search option
            />
            {formik.touched.service && formik.errors.service && (
              <div className="text-red-500 text-sm mb-3">
                {formik.errors.service}
              </div>
            )}
            <textarea
              name="message"
              placeholder="Enter message"
              value={formik.values.message}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              style={
                formik.touched.message && formik.errors.message
                  ? { marginBottom: "1px" }
                  : {}
              }
            />
            {formik.touched.message && formik.errors.message ? (
              <div className="text-red-500 text-sm mb-3">
                {formik.errors.message}
              </div>
            ) : null}
            <button type="submit" className="btn theme-btn-1">
              Send Messege
            </button>
          </form>
        </div>

        {/* Popular Product Widget */}
        <div className="widget ltn__popular-product-widget">
          <h4 className="ltn__widget-title ltn__widget-title-border-2">
            Popular Properties
          </h4>
          <div
            className="row ltn__popular-product-widget-active slick-arrow-1"
            ref={sliderRef1}
          >
            {/* ltn__product-item */}
            {data1.map((element) => {
              let totalSubplots = 0;
              let minPrice = Infinity;
              let maxPrice = -Infinity;
              let minSize = Infinity;
              let maxSize = -Infinity;
              element.blocks.forEach((block) => {
                totalSubplots += block.sub_plot.length;
                block.sub_plot.forEach((subPlot) => {
                  minPrice = Math.min(minPrice, subPlot.price);
                  maxPrice = Math.max(maxPrice, subPlot.price);

                  const sizeInSqFt = parseInt(subPlot.size.split(" ")[0], 10); // Extract size from "100 sq ft"
                  minSize = Math.min(minSize, sizeInSqFt);
                  maxSize = Math.max(maxSize, sizeInSqFt);
                });
              });
              return (
                <div className="col-12">
                  <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
                    <div className="product-img">
                      <Link to={`/product-details/${element._id}`}>
                        <img src={element?.media?.images[0]} alt="#" />
                      </Link>
                      <div className="real-estate-agent">
                        <div className="agent-img">
                          {/* <Link to="/team-details"> */}
                          <img src={logo} alt="#" />
                          {/* </Link> */}
                        </div>
                      </div>
                    </div>
                    <div className="product-info">
                      <div className="product-price">
                        <span>
                          {" "}
                          {minPrice == maxPrice ? (
                            <>₹ {minPrice}</>
                          ) : (
                            <>
                              ₹ {minPrice} - ₹ {maxPrice}
                            </>
                          )}
                        </span>
                      </div>
                      <h2 className="product-title">
                        <Link to={`/product-details/${element._id}`}>
                          {element.name}
                        </Link>
                      </h2>
                      <div className="product-img-location">
                        <ul>
                          <li>
                            <Link to={`/product-details/${element._id}`}>
                              <i className="flaticon-pin" />{" "}
                              {element.location.area}, {element.location.state}
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
                        <li>
                          <span>{element.blocks.length} </span> Blocks
                        </li>
                        <li>
                          <span>{totalSubplots}</span> Sub Plots
                        </li>
                        <li>
                          <span>
                            {" "}
                            {minSize == maxSize ? (
                              <> {minSize}</>
                            ) : (
                              <>
                                {minSize} - {maxSize}
                              </>
                            )}{" "}
                          </span>
                          square Ft
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              );
            })}
            {/*  */}
          </div>
        </div>
        {/* Popular Post Widget */}
        <div className="widget ltn__popular-post-widget">
          <h4 className="ltn__widget-title ltn__widget-title-border-2">
            Leatest Blogs
          </h4>
          <ul>
            {data.map((e) => (
              <li>
                <div className="popular-post-widget-item clearfix">
                  <div className="popular-post-widget-img">
                    <Link to={e.link}>
                      <img src={e.img} alt="#" />
                    </Link>
                  </div>
                  <div className="popular-post-widget-brief">
                    <h6>
                      <Link to={e.link}>{e.name}</Link>
                    </h6>
                    <div className="ltn__blog-meta">
                      <ul>
                        <li className="ltn__blog-date">
                          <i className="far fa-calendar-alt" />
                          {e.date}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </aside>
    </div>
  );
};

export default Sidebar;
