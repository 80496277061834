import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Copyright from "./copyright";
import Social from "./social";
import Logo from "../assets/img/Wind-Smart-Logo.png";

const Footer = () => {
  useEffect(() => {
    const publicUrl = process.env.PUBLIC_URL + "/";
    const minscript = document.createElement("script");
    minscript.src = publicUrl + "assets/js/main.js";
    minscript.async = true;

    document.body.appendChild(minscript);

    const handleGoTopClick = (event) => {
      const overlay = document.querySelector(".quarter-overlay");
      if (overlay) {
        overlay.style.display = "block"; // Show overlay
        window.scrollTo(0, 0); // Scroll to top

        setTimeout(() => {
          overlay.style.display = "none"; // Hide overlay
        }, 800);
      }
    };

    const goTopLinks = document.querySelectorAll(".go-top a");
    goTopLinks.forEach((link) => {
      link.addEventListener("click", handleGoTopClick);
    });

    const handleThemeBtnClick = () => {
      const divs = document.querySelectorAll("div");
      divs.forEach((div) => {
        div.classList.remove("modal-backdrop", "show", "fade");
      });
      document.body.removeAttribute("style");
    };

    const themeBtn1 = document.querySelectorAll(".theme-btn-1");
    themeBtn1.forEach((btn) => {
      btn.addEventListener("click", handleThemeBtnClick);
    });

    return () => {
      // Cleanup event listeners
      goTopLinks.forEach((link) => {
        link.removeEventListener("click", handleGoTopClick);
      });
      themeBtn1.forEach((btn) => {
        btn.removeEventListener("click", handleThemeBtnClick);
      });

      // Remove the script element to avoid memory leaks
      document.body.removeChild(minscript);
    };
  }, []);

  return (
    <footer className="ltn__footer-area">
      <div
        className="footer-top-area section-bg-2 plr--10"
        style={{ backgroundColor: "#262626", color: "white" }}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-4 col-md-6 col-sm-6 col-12">
              <div
                className="footer-widget footer-about-widget"
                style={{ position: "relative", top: "-27px" }}
              >
                <div className="footer-logo">
                  <div
                    className="site-logo"
                    style={{ display: "block", textAlign: "center" }}
                  >
                    <img
                      src={Logo}
                      alt="Logo"
                      className="logo1"
                      style={{ marginRight: "130px" }}
                    />
                  </div>
                </div>
                {/* <p>
                  Founded in 2019, WIND GROUP, recognized as Reputed Builders &
                  Real Estate Developers in REWA, has been built on the belief
                  that ideas form the cornerstones of buildings.
                </p> */}
                <div className="footer-address">
                  <ul>
                    <li>
                      <div className="footer-address-icon">
                        <i className="icon-placeholder" />
                      </div>
                      <div className="footer-address-info">
                        <p>
                          2nd floor Nursingh Complex in front of Shilpi Plaza B
                          block Rewa (M.P)486001
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="footer-address-icon">
                        <i className="icon-call" />
                      </div>
                      <div className="footer-address-info">
                        <p>
                          <a href="tel:+0123-456789">+07662469318</a>
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="footer-address-icon">
                        <i className="icon-mail" />
                      </div>
                      <div className="footer-address-info">
                        <p>
                          <a href="mailto:example@example.com">
                            windnxrealty@gmail.com
                          </a>
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
                {/* <div className="ltn__social-media mt-20">
                  <Social />
                </div> */}
              </div>
            </div>
            <div className="col-xl-3 col-md-6 col-sm-6 col-12">
              <div className="footer-widget footer-menu-widget clearfix">
                <h4 className="footer-title">Company</h4>
                <div className="footer-menu go-top">
                  <ul>
                    <li>
                      <Link to="/about">About Us</Link>
                    </li>
                    <li>
                      <Link to="/blog-details/1">Blog</Link>
                    </li>
                    <li>
                      <Link to="/career">Careers</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 col-sm-6 col-12">
              <div className="footer-widget footer-menu-widget clearfix">
                <h4 className="footer-title">Services</h4>
                <div className="footer-menu go-top">
                  <ul>
                    <li>
                      <Link to="/service">Service</Link>
                    </li>
                    <li>
                    <Link to="/service-details/realEstate">Real Estate Development</Link>
                    </li>
                    <li>
                      <Link to="/product">All Plots</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-md-6 col-sm-6 col-12">
              <div className="footer-widget footer-menu-widget clearfix">
                <h4 className="footer-title">Customer Care</h4>
                <div className="footer-menu go-top">
                  <ul>
                    <li>
                      <Link to="/faq">FAQ</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact us</Link>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Copyright />
    </footer>
  );
};

export default Footer;
