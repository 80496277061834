import React from "react";
import bgVideo from "../assets/video/bgVideo.mp4";

const Video = () => {
  return (
    <div className="ltn__slider-area ltn__slider-4 position-relative ltn__primary-bg">
      <div className="ltn__slide-one-active----- slick-slide-arrow-1----- slick-slide-dots-1----- arrow-white----- ltn__slide-animation-active">
        {/* HTML5 VIDEO */}
        <video autoPlay muted loop id="myVideo">
          <source src={bgVideo} type="video/mp4" />
        </video>
        <div
          className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-7 bg-image--- bg-overlay-theme-black-10---"
          data-bs-bg="img/slider/41.jpg"
        >
        </div>
      </div>
    </div>
  );
};

export default Video;
