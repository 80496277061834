import axios from "axios";
import { errorToast } from "../toastConfig";

const Axios = axios.create({
  baseURL: "https://api.windnxsmart.com", //todo add .env
  // baseURL: "http://localhost:5000",
});

Axios.interceptors.request.use(
  function (request) {
    return request;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

Axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const statusCode = error.response?.status || error.code;
    const errorMessage =
      error.response?.data?.message ||
      error.message ||
      "Something Went Wrong!!";

    switch (statusCode) {
      case 403:
      case 401:
        errorToast(errorMessage);
        break;
      case 404:
        console.log("404");
        return Promise.reject(error);
      case 500:
        console.log("Error 500 ===>", errorMessage, { statusCode });
        if (errorMessage === "ERR_NETWORK") errorToast(errorMessage);
        return Promise.reject(error);
      case "ERR_NETWORK":
        return Promise.reject(error);
      default:
        return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

export default Axios;
