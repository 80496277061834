import React from "react";
import { Link } from "react-router-dom";

const CopyRight = () => {
  const publicUrl = process.env.PUBLIC_URL + "/";

  return (
    <div className="ltn__copyright-area ltn__copyright-2 section-bg-7 plr--5">
      <div className="container-fluid ltn__border-top-2">
        <div className="ltn__copyright-design clearfix flex justify-center">
          <p>
            Developed By @aimbeat.com <span className="current-year" />
          </p>
        </div>
      </div>
    </div>
  );
};

export default CopyRight;
