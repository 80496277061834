import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Logo3 from "../assets/img/Wind-Smart-Logo.png";
import language from "../assets/img/language.png";
import language2 from "../assets/img/language2.png";
import { useTranslation } from "react-i18next";

const Navbar = () => {
  const { t, i18n } = useTranslation();
  const [isHovered, setIsHovered] = useState(false);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng);
  };

  return (
    <div>
      <header className="ltn__header-area ltn__header-logo-and-mobile-menu-in-mobile ltn__header-logo-and-mobile-menu ltn__header-transparent gradient-color-2">
        <div
          className="ltn__header-middle-area ltn__header-sticky "
          style={{ backgroundColor: "#262626" }}
        >
          <div className="flex">
            <div className="col">
              <div className="site-logo-wrap">
                <div className="site-logo go-top">
                  <Link to="/">
                    <img src={Logo3} className="logo my-2" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col header-menu-column menu-color-white">
              <div className="header-menu d-none d-xl-block">
                <nav>
                  <div className="ltn__main-menu go-top">
                    <ul>
                      <li>
                        <Link to="/">
                          {" "}
                          <span className="hover:text-[rgba(231,196,79,1)]">
                            {t("home")}
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link className="menu-icon" to="/about">
                          <span className="hover:text-[rgba(231,196,79,1)]">
                            {t("about")}
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/service">
                          {" "}
                          <span className="hover:text-[rgba(231,196,79,1)]">
                            {t("service")}
                          </span>
                        </Link>
                        <ul
                          className="go-top"
                          style={{
                            top: "70px",
                            left: "0%",
                            padding: "10px",
                            minWidth: "210px",
                            backgroundColor: "#262626",
                            color: "white",
                          }}
                        >
                          <li>
                            <Link to="/service-details/realEstate">
                              {t("realEstate.title")}
                            </Link>
                          </li>
                          <li>
                            <Link to="/service-details/salesMarketing">
                              {t("salesMarketing.title")}
                            </Link>
                          </li>
                          <li>
                            <Link to="/service-details/brokerage">
                              {t("brokerage.title")}
                            </Link>
                          </li>
                          <li>
                            <Link to="/service-details/propertyManagement">
                              {t("propertyManagement.title")}
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link to="/product">
                          {" "}
                          <span className="hover:text-[rgba(231,196,79,1)]">
                            {t("plot")}
                          </span>
                        </Link>
                        <ul
                          className="go-top"
                          style={{
                            top: "70px",
                            left: "0%",
                            padding: "10px",
                            minWidth: "210px",
                            backgroundColor: "#262626",
                            color: "white",
                          }}
                        >
                          <li>
                            <Link to="/availablePlot">Available Plots</Link>
                          </li>
                          {/* <li>
                            <Link to="/product-details">
                              Plot Booking Process
                            </Link>
                          </li> */}
                          <li>
                            <Link to="/faq">Plot Booking FAQs</Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link to="/contact">
                          {" "}
                          <span className="hover:text-[rgba(231,196,79,1)]">
                            {t("contact")}
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/career">
                          {" "}
                          <span className="hover:text-[rgba(231,196,79,1)]">
                            {t("careers")}
                          </span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
            <div className="col header-menu-column menu-color-white flex justify-end">
              <div className="header-menu d-none d-xl-block">
                <nav>
                  <div className="ltn__main-menu go-top">
                    <ul>
                      <li
                        className="ltn__drop-menu user-menu"
                        style={{ marginRight: "0px" }}
                      >
                        <a href="#" className="dropdown-toggle ">
                          <span className="active-currency hover:text-[rgba(231,196,79,1)]">
                            <img
                              src={!isHovered ? language2 : language}
                              height={25}
                              width={25}
                              style={{ maxWidth: "25px", marginBottom: "5px" }}
                              onMouseEnter={() => setIsHovered(true)}
                              onMouseLeave={() => setIsHovered(false)}
                            />
                          </span>
                        </a>
                        <ul
                          className="go-top"
                          style={{
                            top: "70px",
                            left: "0%",
                            padding: "0px",
                            minWidth: "100px",
                            backgroundColor: "#262626",
                            color: "white",
                          }}
                        >
                          <li
                            onClick={() => changeLanguage("en")}
                            style={{ padding: 0 }}
                          >
                            <Link to="#">English</Link>
                          </li>
                          <li
                            onClick={() => changeLanguage("hi")}
                            style={{ padding: 0 }}
                          >
                            <Link to="#">हिंदी</Link>
                          </li>
                        </ul>
                      </li>
                      <li style={{ marginTop: "3px", marginRight: "0px" }}>
                        <div
                          className="col ltn__header-options ltn__header-options-2 mt-10"
                          style={{ paddingLeft: "0px", paddingRight: "0px" }}
                        >
                          <div
                            className="header-search-wrap"
                            style={{ backgroundColor: "#262626" }}
                          >
                            {/* <div className="header-search-1">
                              <div className="search-icon">
                                <i className="icon-search for-search-show icon-css" />
                                <i className="icon-cancel  for-search-close icon-css" />
                              </div>
                            </div> */}
                            <div className="header-search-1-form">
                              <form id="#" method="get" action="#">
                                <input
                                  type="text"
                                  name="search"
                                  placeholder="Search here..."
                                />
                                <button type="submit">
                                  <span>
                                    <i className="icon-search" />
                                  </span>
                                </button>
                              </form>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li style={{ marginRight: "0px", marginTop: "3px" }}>
                        <div
                          className="col ltn__header-options ltn__header-options-2"
                          style={{
                            paddingLeft: "6px",
                            paddingRight: "6px",
                          }}
                        >
                          <div className="ltn__drop-menu">
                            <ul>
                              <li>
                                <Link
                                  to="https://app.windnxsmart.com/login"
                                  style={{
                                    width: "85px",
                                    height: "35px",
                                    lineHeight: "35px",
                                    marginTop: "4px",
                                    borderRadius: "2px",
                                  }}
                                >
                                  {t("signIn")}
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                      <li style={{ marginRight: "0px", marginTop: "3px" }}>
                        <div
                          className="col ltn__header-options ltn__header-options-2"
                          style={{ paddingLeft: "6px", paddingRight: "6px" }}
                        >
                          <div className="ltn__drop-menu">
                            <ul>
                              <li>
                                <Link
                                  to="https://app.windnxsmart.com/register"
                                  style={{
                                    width: "85px",
                                    height: "35px",
                                    lineHeight: "35px",
                                    marginTop: "4px",
                                    borderRadius: "2px",
                                  }}
                                >
                                  {t("register")}
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
            <div className="mobile-menu-toggle d-xl-none">
              <a
                href="#ltn__utilize-mobile-menu"
                className="ltn__utilize-toggle"
              >
                <svg viewBox="0 0 800 600">
                  <path
                    class="white-path"
                    d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200"
                    id="top"
                    stroke="white"
                  />
                  <path class="white-path" d="M300,320 L540,320" id="middle" />
                  <path
                    class="white-path"
                    d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190"
                    id="bottom"
                    transform="translate(480, 320) scale(1, -1) translate(-480, -318)"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
        {/* ltn__header-middle-area end */}
      </header>

      <div
        id="ltn__utilize-mobile-menu"
        className="ltn__utilize ltn__utilize-mobile-menu"
        style={{ backgroundColor: "#262626", color: "white" }}
      >
        <div className="ltn__utilize-menu-inner ltn__scrollbar">
          <div className="ltn__utilize-menu-head">
            <div className="site-logo">
              <Link to="/">
                <img
                  src={Logo3}
                  alt="Logo"
                  height={35}
                  width={200}
                  // style={{ marginTop: "-30px" }}
                />
              </Link>
            </div>
          </div>
          {/* <div className="ltn__utilize-menu-search-form"> */}
          {/* <form action={"#"}>
              <input
                type="text"
                placeholder="Search..."
                style={{ height: "33px" }}
              />
              <button>
                <i className="fas fa-search" />
              </button>
            </form> */}
          {/* </div> */}
          <div className="ltn__utilize-menu">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
                <Link to="/service">Service</Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/service-details/realEstate">
                      Real Estate Development
                    </Link>
                  </li>
                  <li>
                    <Link to="/service-details/salesMarketing">
                      Sales and Marketing
                    </Link>
                  </li>
                  <li>
                    <Link to="/service-details/brokerage">Brokerage</Link>
                  </li>
                  <li>
                    <Link to="/service-details/propertyManagement">
                      Property Management
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/product">Plot Booking</Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/availablePlot">Available Plots</Link>
                  </li>
                  <li>
                    <Link to="/faq">Plot Booking FAQs</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
              <li>
                <Link to="/career">Careers</Link>
              </li>
            </ul>
          </div>
          <div className="ltn__utilize-menu ltn__utilize-buttons ltn__utilize-buttons-2 ">
            <ul>
              <li>
                <Link to="https://app.windnxsmart.com/login">
                  <span className="utilize-btn-icon">
                    <i className="far fa-user" />
                  </span>
                  Login
                </Link>
              </li>
              <li>
                <Link to="https://app.windnxsmart.com/register">
                  <span className="utilize-btn-icon">
                    <i className="far fa-user" />
                  </span>
                  Register
                </Link>
              </li>
            </ul>
          </div>
          {/* <div className="ltn__social-media-2">
            <ul>
              <li>
                <a href="#" title="Facebook">
                  <i className="fab fa-facebook-f" />
                </a>
              </li>
              <li>
                <a href="#" title="Twitter">
                  <i className="fab fa-twitter" />
                </a>
              </li>
              <li>
                <a href="#" title="Linkedin">
                  <i className="fab fa-linkedin" />
                </a>
              </li>
              <li>
                <a href="#" title="Instagram">
                  <i className="fab fa-instagram" />
                </a>
              </li>
            </ul>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
