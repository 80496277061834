import React from "react";

const ContactInfo = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";

  return (
    <div className="ltn__contact-address-area mb-90">
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
              <div className="ltn__contact-address-icon">
                <img
                  src={publicUrl + "assets/img/icons/10.png"}
                  alt="Icon Image"
                />
              </div>
              <h3>Email Address</h3>
              <p>
                windnxrealty@gmail.com <br />
                <p style={{ visibility: "hidden" }}>sdfs</p>
              </p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
              <div className="ltn__contact-address-icon">
                <img
                  src={publicUrl + "assets/img/icons/11.png"}
                  alt="Icon Image"
                />
              </div>
              <h3>Phone Number</h3>
              <p>
                +07662469318 <br />
                <p style={{ visibility: "hidden" }}>sdfs</p>
              </p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
              <div className="ltn__contact-address-icon">
                <img
                  src={publicUrl + "assets/img/icons/12.png"}
                  alt="Icon Image"
                />
              </div>
              <h3>Office Address</h3>
              <p>
                2nd floor Nursingh Complex in front of <br /> Shilpi Plaza B
                block Rewa (M.P) 486001
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
