import React from "react";
import Navbar from "../component/navbar";
import Video from "../component/video";
import Footer from "../component/footer";
import Counter from "../component/counter";
import ProductSlider from "../component/slider";
import Apartment from "../component/appartment";
import AboutUsCard from "../component/aboutUsCard";
import BlogSlider from "../component/blogSlider";
import Testimonial from "../component/testimonial";
import SearchForm from "../component/searchForm";

const Home = () => {
  return (
    <div>
      <Navbar CustomClass="ltn__header-transparent gradient-color-2" />
      <Video />
      <SearchForm/>
      <Counter />
      <AboutUsCard />
      <ProductSlider />
      <Testimonial />
      <Apartment />
      <BlogSlider customClass="section-subtitle-2" />
      <Footer />
    </div>
  );
};

export default Home;
