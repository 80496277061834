import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { setSearch } from "../features/authSlice";
import { useTranslation } from "react-i18next";

const SearchForm = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { search } = useSelector((state) => state.auth);

  return (
    <div className="ltn__car-dealer-form-area mt--248 mt-120 pb-115--- bg-[#F2F6F7]">
      {/* <div className="ltn__slide-item-inner text-center"> */}
      <div className="container" style={{ maxWidth: "1020px" }}>
        <div className="row">
          <div className="col-lg-12 align-self-center">
            <div className="slide-item-car-dealer-form">
              <div
                className="ltn__section-title-2 text-center"
                style={{ marginBottom: "0px" }}
              >
                <motion.h1
                  initial={{ scale: 1 }} // Initial scale
                  whileHover={{ scale: 1.2 }} // Scale up on hover
                  whileTap={{ scale: 1 }} // Reset to normal size on mouse down
                  transition={{ type: "spring", stiffness: 300, damping: 20 }} // Smooth animation transition
                  className="section-title text-color-white"
                >
                  <span className="ltn__secondary-color">
                    {" "}
                    {t("perfectPlot")}
                  </span>{" "}
                </motion.h1>
              </div>
              <div className="ltn__car-dealer-form-tab">
                <div className="tab-content !bg-[#262626]">
                  {/* Rent Home Form */}
                  <div
                    className="tab-pane fade active show"
                    id="ltn__form_tab_1_1"
                  >
                    <div className="car-dealer-form-inner">
                      <form action="#" className="ltn__car-dealer-form-box row">
                        <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-car col-lg-10 col-md-9 mt-2">
                          <input
                            type="text"
                            value={search}
                            placeholder={t("homeplaceHolder")}
                            style={{
                              borderRadius: "2px",
                              fontWeight: "bold",
                              marginBottom: "0px",
                            }}
                            onChange={(e) => {
                              dispatch(setSearch(e.target.value));
                            }}
                          />
                        </div>
                        <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-calendar col-lg-2 col-md-3 mt-2">
                          <div className="btn-wrapper text-center mt-0 go-top">
                            <Link
                              to="/product"
                              style={{ borderRadius: "2px" }}
                              className="btn theme-btn-1 btn-effect-1 text-uppercase"
                            >
                              {t("search")}
                            </Link>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchForm;
