import React from "react";
import Footer from "../component/footer";
import Navbar from "../component/navbar";
import PageHeader from "../component/Page-header";
import ProductDetails from "../component/productDetails";

const Product_Details = () => {
  return (
    <div>
      <Navbar />
      <PageHeader headertitle="Product Details" customclass="mb-0" />
      <ProductDetails/>
      <Footer />
    </div>
  );
};

export default Product_Details;
