import React from "react";
import Footer from "../component/footer";
import Navbar from "../component/navbar";
import PageHeader from "../component/Page-header";
import BlogDetails from "../component/blogDetails";

const BlogDetailsPage = () => {
  return (
    <div>
      <Navbar CustomClass="ltn__header-transparent gradient-color-2" />
      <PageHeader headertitle="News and Blogs" subheader="Blog Details" />
      <BlogDetails />
      <Footer />
    </div>
  );
};

export default BlogDetailsPage;
