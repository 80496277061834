import React from "react";
import BlogSlider from "../component/blogSlider";
import Counter from "../component/counter";
import FaqV1 from "../component/faqCard";
import Footer from "../component/footer";
import Navbar from "../component/navbar";
import PageHeader from "../component/Page-header";

const Faq = () => {
  return (
    <div>
      <Navbar CustomClass="ltn__header-transparent gradient-color-2" />
      <PageHeader headertitle="Frequently asked questions" subheader="FAQ" />
      <FaqV1 />
      <Counter />
      <BlogSlider sectionClass="pt-120" />
      <Footer />
    </div>
  );
};

export default Faq;
