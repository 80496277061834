import React from "react";
import { useFormik } from "formik";
import { errorToast, successToast } from "../toastConfig";
import * as Yup from "yup";
import { createCareer } from "../service/axiosInstance";

const CareerPage = () => {
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    phone: Yup.string().required("Phone number is required"),
    position: Yup.string().required("Position is required"),
    message: Yup.string().required("Message is required"),
    file: Yup.mixed().required("File is required"),
  });
  // Initialize Formik
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      position: "",
      message: "",
      file: null,
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      // Handle form submission
      handleSubmit(values, resetForm);
      // You can send values to a backend here
    },
  });

  const handleSubmit = async (values, resetForm) => {
    try {
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("email", values.email);
      formData.append("phone", values.phone);
      formData.append("position", values.position);
      formData.append("message", values.message);

      // Append file if provided
      if (values.file) {
        formData.append("resume", values.file);
      }

      const res = await createCareer(formData);

      if (res.status == 200) {
        successToast(res.data.message);
        resetForm();
      }
    } catch (error) {
      errorToast(error.message);
    }
  };

  return (
    <div className="career-page">
      {/* Contact Information Section */}
      <div className="ltn__contact-message-area mb-50">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="ltn__form-box contact-form-box box-shadow white-bg">
                <h4 className="title-2">Submit Your Resume</h4>
                <form onSubmit={formik.handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input-item input-item-name ltn__custom-icon">
                        <input
                          type="text"
                          name="name"
                          placeholder="Enter your name"
                          onChange={formik.handleChange}
                          value={formik.values.name}
                          style={
                            formik.touched.name && formik.errors.name
                              ? { marginBottom: "10px" }
                              : {}
                          }
                        />
                        {formik.touched.name && formik.errors.name ? (
                          <div className="text-red-500 text-sm mb-5">
                            {formik.errors.name}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-item input-item-email ltn__custom-icon">
                        <input
                          type="email"
                          name="email"
                          placeholder="Enter email address"
                          onChange={formik.handleChange}
                          value={formik.values.email}
                          style={
                            formik.touched.email && formik.errors.email
                              ? { marginBottom: "10px" }
                              : {}
                          }
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <div className="text-red-500 text-sm mb-5">
                            {formik.errors.email}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-item input-item-phone ltn__custom-icon">
                        <input
                          type="text"
                          name="phone"
                          placeholder="Enter phone number"
                          onChange={formik.handleChange}
                          value={formik.values.phone}
                          style={
                            formik.touched.phone && formik.errors.phone
                              ? { marginBottom: "10px" }
                              : {}
                          }
                        />
                        {formik.touched.phone && formik.errors.phone ? (
                          <div className="text-red-500 text-sm mb-5">
                            {formik.errors.phone}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-item input-item-subject ltn__custom-icon">
                        <input
                          type="text"
                          name="position"
                          placeholder="Enter your Position"
                          onChange={formik.handleChange}
                          value={formik.values.position}
                          style={
                            formik.touched.position && formik.errors.position
                              ? { marginBottom: "10px" }
                              : {}
                          }
                        />
                        {formik.touched.position && formik.errors.position ? (
                          <div className="text-red-500 text-sm mb-5">
                            {formik.errors.position}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div
                        className="input-item ltn__custom-icon"
                        style={{ height: "90px" }}
                      >
                        <input
                          type="file"
                          name="file"
                          id="file-upload"
                          onChange={(event) => {
                            formik.setFieldValue(
                              "file",
                              event.currentTarget.files[0]
                            );
                          }}
                          style={
                            formik.touched.file && formik.errors.file
                              ? {
                                  marginBottom: "10px",
                                  height: "65px",
                                  border: "2px solid #E4ECF2",
                                  width: "100%",
                                  padding: "15px",
                                }
                              : {
                                  height: "65px",
                                  border: "2px solid #E4ECF2",
                                  width: "100%",
                                  padding: "15px",
                                }
                          }
                        />
                        {formik.touched.file && formik.errors.file ? (
                          <div className="text-red-500 text-sm mb-5">
                            {formik.errors.file}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="input-item input-item-textarea ltn__custom-icon">
                    <textarea
                      name="message"
                      placeholder="Enter message"
                      onChange={formik.handleChange}
                      value={formik.values.message}
                      style={
                        formik.touched.message && formik.errors.message
                          ? { marginBottom: "10px", marginTop: "10px" }
                          : {}
                      }
                    />
                    {formik.touched.message && formik.errors.message ? (
                      <div className="text-red-500 text-sm mb-5">
                        {formik.errors.message}
                      </div>
                    ) : null}
                  </div>
                  <div className="btn-wrapper mt-0">
                    <button
                      className="btn theme-btn-1 btn-effect-1 text-uppercase"
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="contact-info p-8 bg-white">
        <h2 className="text-2xl font-semibold text-gray-800 text-center">
          Contact Us
        </h2>
        <p className="text-lg text-center text-gray-600 mt-4">
          For more information, feel free to reach out to us:
        </p>
        <div className="text-center mt-4">
          <p className="text-lg text-gray-600">Email: windnxrealty@gmail.com</p>
        </div>
      </section>
    </div>
  );
};

export default CareerPage;
