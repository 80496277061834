import React from "react";
import AboutUs2 from "../component/aboutUsCard2";
import BlogSlider from "../component/blogSlider";
import Footer from "../component/footer";
import Navbar from "../component/navbar";
import PageHeader from "../component/Page-header";
import ServiceV2 from "../component/serviceV2";

const Service = () => {
  return (
    <div>
      <Navbar CustomClass="ltn__header-transparent gradient-color-2" />
      <PageHeader headertitle="What We Do" subheader="Service" />
      <AboutUs2 />
      <ServiceV2 />
      <BlogSlider customClass="section-subtitle-2" />
      <Footer />
    </div>
  );
};

export default Service;
