import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import About from "./layout/aboutUs";
import BlogDetailsPage from "./layout/blog";
import Career from "./layout/career";
import Contact from "./layout/contact";
import Faq from "./layout/faq";
import Home from "./layout/home";
import Product from "./layout/product";
import Product_Details from "./layout/product-details";
import RealEstate from "./layout/realEstate";
import Service from "./layout/service";
import ScrollToTop from "./Scrollup";

function App() {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language") || "en";
    i18n.changeLanguage(savedLanguage);
  }, []);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/service" element={<Service />} />
        <Route path="/service-details/:id" element={<RealEstate />} />
        <Route path="/about" element={<About />} />
        <Route path="/product" element={<Product />} />
        <Route path="/availablePlot" element={<Product />} />
        <Route path="/product-details/:id" element={<Product_Details />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/career" element={<Career />} />
        <Route path="/blog-details/:id" element={<BlogDetailsPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
