import { useFormik } from "formik";
import React from "react";
import Select from "react-select";
import * as Yup from "yup";
import { createLeads } from "../service/axiosInstance";
import { errorToast, successToast } from "../toastConfig";

const ContactForm = () => {
  // Define validation schema using Yup
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    phone: Yup.string()
      .required("Phone number is required")
      .matches(/^\d{10}$/, "Phone number must be exactly 10 digits"),
    service: Yup.string().required("Please select a service"),
    message: Yup.string().required("Message is required"),
  });

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border:
        state.isFocused || state.isHovered
          ? "1px solid #e7c44f"
          : "2px solid #e5eaee",
      boxShadow: state.isFocused ? "none" : "none", // Removes the default focus shadow
      borderRadius: "0",
      height: "65px",
      fontSize: "14px",
      fontWeight: "700",
      lineHeight: "60px",
      width: "100%",
      marginBottom: "10px",
      "&:hover": {
        border: "1px solid #e7c44f", // Border color on hover
        boxShadow: "none", // No default shadow on hover
      },
      position: "relative", // Position context for the dropdown indicator
      display: "flex",
      alignItems: "center", // Aligns text and icon vertically
      justifyContent: "space-between", // Positions the icon to the right
      cursor: "pointer", // Change cursor to indicate interactivity
      color: "#6B7F89",
      paddingBottom: "20px",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontWeight: "700",
      paddingBottom: "20px",
    }),
    indicatorSeparator: (provided) => ({
      display: "none",
      paddingBottom: "45px",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#e7c44f",
      paddingBottom: "45px",
    }),
    menuList: (provided) => ({
      ...provided,
      backgroundColor: "white", // Set background for the options menu
      zIndex: 1,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor:
        state.isFocused || state.isSelected
          ? "#f6f6f6"
          : state.isHovered
          ? "#f1f1f1"
          : "white", // Grey on hover
      color: state.isFocused || state.isSelected ? "#647984" : "#6B7F89", // Change text color on hover or selection
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#f1f1f1", // Grey background for hovered option
      },
      zIndex: 9999999999999,
      paddingLeft: "20px",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#888", // Placeholder text color
      paddingBottom: "20px",
    }),

    valueContainer: (provided) => ({
      ...provided,
      color: "#6B7F89",
      paddingLeft: "15px", // Add padding to the left for the selected value
      paddingBottom: "20px",
    }),
  };

  const options = [
    { value: "Plot Related", label: "Plot Related" },
    { value: "Career", label: "Career" },
    { value: "Enquiry", label: "Enquiry" },
    { value: "Payment Issue", label: "Payment Issue" },
    { value: "Other", label: "Other" },
  ];

  // Initialize Formik
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      service: "",
      message: "",
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      handleSubmit(values, resetForm);
    },
  });

  const handleSubmit = async (values, resetForm) => {
    try {
      const data = {
        customer_info: {
          name: values.name,
          phone: values.phone,
          Preference: "website",
        },
        leads_source: values.service,
        remarks: values.message,
        interaction: "call",
      };
      const res = await createLeads(data);
      if (res.status == 201) {
        successToast("Your Form has been Submitted");
        resetForm();
      }
    } catch (error) {
      errorToast(error.message);
    }
  };

  return (
    <div className="ltn__contact-message-area mb-120">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="ltn__form-box contact-form-box box-shadow white-bg">
              <h4 className="title-2">Get A Quote</h4>
              <form
                onSubmit={formik.handleSubmit}
                id="contact-form"
                className="ltn__car-dealer-form-box"
              >
                <div className="row">
                  <div className="col-md-6">
                    <div className="input-item input-item-name ltn__custom-icon">
                      <input
                        type="text"
                        name="name"
                        placeholder="Enter your name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        style={
                          formik.touched.name && formik.errors.name
                            ? { marginBottom: "10px" }
                            : {}
                        }
                      />
                      {formik.touched.name && formik.errors.name ? (
                        <div className="text-red-500 text-sm mb-5">
                          {formik.errors.name}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-item input-item-email ltn__custom-icon">
                      <input
                        type="email"
                        name="email"
                        placeholder="Enter email address"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        style={
                          formik.touched.email && formik.errors.email
                            ? { marginBottom: "10px" }
                            : {}
                        }
                      />
                      {formik.touched.email && formik.errors.email ? (
                        <div className="text-red-500 text-sm mb-5">
                          {formik.errors.email}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <Select
                      name="service"
                      value={options.find(
                        (option) => option.value === formik.values.service
                      )}
                      onChange={(option) =>
                        formik.setFieldValue("service", option.value)
                      }
                      options={options}
                      styles={customStyles}
                      placeholder="Select Service Type"
                      isSearchable={false} // Disabling the search option
                    />
                    {formik.touched.service && formik.errors.service && (
                      <div className="text-red-500 text-sm mb-5">
                        {formik.errors.service}
                      </div>
                    )}
                  </div>
                  <div className="col-md-6">
                    <div className="input-item input-item-phone ltn__custom-icon">
                      <input
                        type="text"
                        name="phone"
                        placeholder="Enter phone number"
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        style={
                          formik.touched.phone && formik.errors.phone
                            ? { marginBottom: "10px" }
                            : {}
                        }
                      />
                      {formik.touched.phone && formik.errors.phone ? (
                        <div className="text-red-500 text-sm mb-5">
                          {formik.errors.phone}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="input-item input-item-textarea ltn__custom-icon">
                  <textarea
                    name="message"
                    placeholder="Enter message"
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    style={
                      formik.touched.message && formik.errors.message
                        ? { marginBottom: "10px" }
                        : {}
                    }
                  />
                  {formik.touched.message && formik.errors.message ? (
                    <div className="text-red-500 text-sm mb-5">
                      {formik.errors.message}
                    </div>
                  ) : null}
                </div>
                <div className="btn-wrapper mt-0">
                  <button
                    className="btn theme-btn-1 btn-effect-1 text-uppercase"
                    type="submit"
                    style={{
                      position: "relative", // Ensure the button is correctly positioned in the stacking context
                      zIndex: 0, // Lower z-index than the dropdown to keep the dropdown above it
                    }}
                  >
                    Get a free service
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
