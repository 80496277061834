import React from "react";
import { useTranslation } from "react-i18next";

const Testimonial = () => {
  const publicUrl = process.env.PUBLIC_URL + "/";
  const imagealt = "image";

  const {t} = useTranslation()

  return (
    <div
      className="ltn__testimonial-area section-bg-1--- bg-image-top pt-115 pb-70"
      data-bs-bg={publicUrl + "assets/img/bg/20.jpg"}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title-area ltn__section-title-2--- text-center">
              <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                Our Testimonial
              </h6>
              <h1 className="section-title">{t('client')}</h1>
            </div>
          </div>
        </div>
        <div className="row ltn__testimonial-slider-5-active slick-arrow-1">
          {[
            {
              feedback:
                `${t('feedback1')}`
            },
            {
              feedback:
                `${t('feedback2')}`,
            },
            {
              feedback:
                `${t('feedback3')}`,
            },
            {
              feedback:
                `${t('feedback4')}`,
            },
          ].map((testimonial, index) => (
            <div className="col-lg-4" key={index}>
              <div
                className="ltn__testimonial-item ltn__testimonial-item-7"
                style={{ minHeight: "350px" }}
              >
                <div className="ltn__testimoni-info">
                  <p>
                    <i className="flaticon-left-quote-1" />{" "}
                    {testimonial.feedback}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
