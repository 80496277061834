import Axios from "./axios";

export const getAllPlot = (data) => {
  return Axios.get("/api/v1/plots/search", { params: data });
};

export const getMainPlot = (id) => {
  return Axios.get(`api/v1/plots/getplot/${id}`);
};

export const getAvailablePlot = (data) => {
  return Axios.get(`api/v1/plots/availablePlot`, { params: data });
};

export const createLeads = (data) => {
  return Axios.post(`/api/v1/leads/create-leads-website`, data);
};

export const createCareer = (data) => {
  return Axios.post(`/api/v1/employee/createCareer`, data);
};
